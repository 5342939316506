import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { ChevronRightSimple, Star } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { useGetDelayRequest } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { UserPreferences } from "../../store/UserPreferences";
export default function CardReservation({
  cardReservationInfo,
  handleOpenReservationPanel = null,
  hoteMode = false,
}) {
  const handleUpdateReservationParameters = useStoreManager(
    (state) => state.handleUpdateReservationParameters
  );
  const navigate = useNavigate();
  const handleAcceptReservation = () => {};
  const handleGoToResidence = () => {
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let startDate2 = new Date(cardReservationInfo.fromDate);
    let endDate2 = new Date(cardReservationInfo.toDate);
    let year = startDate2.getFullYear();
    let month = (startDate2.getMonth() + 1).toString().padStart(2, "0");
    let day = startDate2.getDate().toString().padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;
    startDate2 = formattedDate;
    year = endDate2.getFullYear();
    month = (endDate2.getMonth() + 1).toString().padStart(2, "0");
    day = endDate2.getDate().toString().padStart(2, "0");
    formattedDate = `${year}-${month}-${day}`;
    endDate2 = formattedDate;
    handleUpdateReservationParameters({
      residenceId: cardReservationInfo.residence.id,
      residence: cardReservationInfo.residence,
      lastPage: "/profilereservation",
      startDate: new Date(startDate2),
      endDate: new Date(endDate2),
      nbrPersonne: cardReservationInfo.adults,
      reservation: cardReservationInfo,
    });
    let timer = setTimeout(() => {
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      navigate("/reservation/" + cardReservationInfo.id);
    }, 1000);
  };
  const { t } = useTranslation();
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalAccept, setOpenModalAccept] = useState(false);
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = UserPreferences((state) => state.language);

  let newValueSepared = 0;
  if (
    devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
  ) {
    newValueSepared = cardReservationInfo.total;
    if (hoteMode) {
      newValueSepared = cardReservationInfo.hostMoney;
    }
  } else if (
    devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
  ) {
    // newValueSepared = cardReservationInfo.priceEuro;
  } else if (
    devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
  ) {
    // newValueSepared = cardReservationInfo.priceDollars;
  }
  newValueSepared = handleFormatToLocalString(
    newValueSepared.toString(),
    language
  );
  let dataStart = moment(
    cardReservationInfo.fromDate,
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  ).toDate();
  let dataEnd = moment(
    cardReservationInfo.toDate,
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  ).toDate();
  const [currentShowDate, setCurrentShowDate] = useState(<></>);
  const statusPay = cardReservationInfo.status;
  const compareDates = () => {
    const startYear = moment(dataStart).year();
    const endYear = moment(dataEnd).year();
    const startMonth = moment(dataStart).month();
    const endMonth = moment(dataEnd).month();

    if (startYear === endYear && startMonth === endMonth) {
      const content = (
        <>
          <p>
            {moment(dataStart).format("D")}-{moment(dataEnd).format("D")}
          </p>
          <hr />
          <p>{moment(dataStart).format("MMM")}</p>
          <hr />
          <p>{startYear}</p>
        </>
      );
      setCurrentShowDate(content);
    } else {
      const content = (
        <>
          <p>
            {moment(dataStart).format("D")}-{moment(dataStart).format("MMM")}-
            {startYear}
          </p>
          <hr />
          <p>
            {moment(dataEnd).format("D")}-{moment(dataEnd).format("MMM")}-
            {endYear}
          </p>
        </>
      );
      setCurrentShowDate(content);
    }
  };
  useEffect(() => {
    compareDates();
  }, []);
  //"all status"
  //waiting = le client a fait sa demande et elle est en attente de validation par l'hote
  //accepted = l'hote a validé la demande et le client on doit maintenant demandé au client de faire le paiement
  //planified = le client a payé et sa reservation est complètement validé. On attend donc le jour de la reservation
  //progressing = le client a pris possession du bien (l'hote a saisi son code de validation de reservation)
  //rejected = l'hote a rejeté la demande de reservation. Il peut le faire uniquement quand la demande est en attente (waiting)
  //cancelled = l'hote ou le client ont annulé la reservation.
  // done = le client a validé que la reservation est terminée (ou le systeme a passé sa reservation en terminé après que la date de fin soit passée
  let valueToShow = <></>;
  const [showModalCancelStatusPay, setShowModalCancelStatusPay] =
    useState(false);
  useEffect(() => {
    // console.log("reservation", cardReservationInfo);
  }, []);
  if (!showModalCancelStatusPay) {
    switch (statusPay) {
      case "waiting":
        if (hoteMode) {
          valueToShow = (
            <span className="top-corner-info">{t("waitingTxt")}</span>
          );
        } else {
          valueToShow = (
            <span className="top-corner-info">
              {t("waitiwaitingTxtngHotelTxt")}
            </span>
          );
        }
        break;
      case "accepted" || "payment_processing":
        if (statusPay == "payment_processing" && !hoteMode) {
          valueToShow = (
            <span className="top-corner-info">{"Paiement en cours"}</span>
          );
        } else
          valueToShow = (
            <span className="top-corner-info">{t("waitingPaiementTxt")}</span>
          );
        break;
      case "planified":
        if (hoteMode) {
          if (statusPay === "progressing") {
            valueToShow = <span className="top-corner-info">En cours</span>;
          } else {
            valueToShow = (
              <span className="top-corner-info">{t("paidConfirm")}</span>
            );
          }
        } else {
          valueToShow = (
            <span className="top-corner-info">{t("paiementAcceptTxt")}</span>
          );
        }
        break;
      case "cancelled":
        valueToShow = (
          <span className="top-corner-info">{t("cancelByClient")}</span>
        );
        break;
      case "rejected":
        if (hoteMode) {
          valueToShow = (
            <span className="top-corner-info">{t("btnCancel")}</span>
          );
        } else {
          valueToShow = (
            <span className="top-corner-info">{t("paiementCancelByHote")}</span>
          );
        }
        break;
      case "done":
        valueToShow = (
          <span className="top-corner-info">{t("reservationDoneRsvTxt")}</span>
        );
      default:
        break;
    }
  }

  if (cardReservationInfo.refundAskedAt && hoteMode == false) {
    valueToShow = (
      <span className="top-corner-info">Remboursement en examen</span>
    );
  }
  if (cardReservationInfo.refundAskedAt && hoteMode == true) {
    valueToShow = (
      <span className="top-corner-info">Remboursement demandée</span>
    );
  }

  const [paiementValide, setPaiementValide] = useState(false);

  if (cardReservationInfo.status == "payment_processing" && hoteMode == false) {
    valueToShow = <span className="top-corner-info">Paiement en cours</span>;
  }
  if (
    cardReservationInfo.status == "payment_processing" &&
    hoteMode == false &&
    paiementValide
  ) {
    valueToShow = <span className="top-corner-info"></span>;
  }
  if (cardReservationInfo.status == "payment_processing" && hoteMode == true) {
    valueToShow = <span className="top-corner-info">Paiement en attente</span>;
  }

  if (
    cardReservationInfo.status == "accepted" &&
    hoteMode == false &&
    showModalCancelStatusPay
  ) {
    valueToShow = <span className="top-corner-info">Paiement echoué</span>;
  }
  if (cardReservationInfo.refundedAt) {
    hoteMode
      ? (valueToShow = <span className="top-corner-info">A été remboursé</span>)
      : (valueToShow = (
          <span className="top-corner-info">Remboursement confirmé</span>
        ));
  }
  const [dataAllReservations, loading, error, fetchData] = useGetDelayRequest({
    route: requestEndpoint.getReservationUser,
    loadingTime: 0,
    delay: 0,
  });

  //verifier chaque 10 secondes si le paiement est toujours en cours avec fetchdata
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    if (cardReservationInfo.status != "payment_processing") return;
    if (hoteMode) return;
    fetchData();
  }, []);
  useEffect(() => {
    if (loading) return;
    if (!dataAllReservations) return;
    if (dataAllReservations.data.length == 0) return;
    let newTimer = null;
    dataAllReservations.data.map((reservation) => {
      if (reservation.id == cardReservationInfo.id) {
        if (reservation.status == "planified") {
          setPaiementValide(true);
          NotificationService.addToQueue(
            0,
            "Paiement de la réservation validé, la page va donc se recharger dans 4 secondes"
          );
          clearTimeout(timer);

          newTimer = setTimeout(() => {
            window.location.reload();
          }, 3000);
          return;
        }
        if (reservation.status == "accepted") {
          setShowModalCancelStatusPay(true);
          setPaiementValide(true);
          NotificationService.addToQueue(
            2,
            "Le paiement à échoué, rechargez la page pour pouvoir réessayer"
          );
          clearTimeout(timer);
          return;
        }
      }
    });
    if (showModalCancelStatusPay) return;
    newTimer = setTimeout(() => {
      fetchData();
    }, 5000);
    setTimer(newTimer);
    return () => {
      clearTimeout(timer);
    };
  }, [dataAllReservations, loading, error]);
  // console.log(cardReservationInfo);

  // console.log(cardReservationInfo.fromDate, cardReservationInfo.toDate);
  let clientName = "";
  if (cardReservationInfo) {
    clientName =
      cardReservationInfo?.user?.firstname +
      " " +
      cardReservationInfo?.user?.lastname;
    clientName = clientName?.replace(/\b\w/g, (l) => l.toUpperCase());
  }
  if (cardReservationInfo.status == "progressing") {
    valueToShow = <span className="top-corner-info">En cours</span>;
  }
  return (
    <div
      style={{ padding: 16 }}
      className={`${
        statusPay == "payment_processing" &&
        hoteMode == false &&
        !paiementValide
          ? "flash-me"
          : ""
      } ${
        showModalCancelStatusPay ? "echec-paiement" : ""
      } reservation-card | flrow rlve`}
    >
      <button
        onClick={() =>
          showModalCancelStatusPay
            ? NotificationService.addToQueue(
                2,
                "Le paiement à échoué, rechargez la page pour pouvoir réessayer"
              )
            : handleOpenReservationPanel(cardReservationInfo, true)
        }
        className="left-side"
      >
        <img
          className="img-thumbs"
          src={
            cardReservationInfo.residence.medias
              ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${cardReservationInfo.residence.medias[0]?.filename}`
              : `${process.env.REACT_APP_API_URL}/assets/home-banner.jpg`
          }
          alt="img-thumbs"
        />
      </button>
      {valueToShow}
      <div className="txt-part | flcolm | btw">
        <div className="top-title | flcolm">
          <span className="title">{cardReservationInfo.residence.name}</span>
          <div className="location | flrow liner">
            <div className="star | flrow liner">
              <Star
                color={
                  cardReservationInfo.residence.stars
                    ? "#FACC15"
                    : "var(--shade-black)"
                }
              />
              <span>
                {cardReservationInfo.residence?.stars
                  ? parseFloat(
                      cardReservationInfo.residence?.stars?.toString()
                    ).toFixed(1)
                  : "N/A"}
              </span>
            </div>
            <hr />
            <p>{cardReservationInfo.residence.address}</p>
          </div>
        </div>
        <div className="middle-part | flcolm">
          <div className="one-part | flrow liner">{currentShowDate}</div>
          <div className="one-part | flrow liner">
            {hoteMode ? (
              <span>{t("gainsTotauxTxt")}</span>
            ) : (
              <span>{t("montantToBePaidTxt")}</span>
            )}
            <hr />
            <p>
              {newValueSepared} {showDevise}
            </p>
          </div>
        </div>
        {/*  0 En attente de l'hôte */}
        {/*  1 En attente de paiement */}
        {/*  2 Paiement accepté */}
        {/*  3 Refuse par vous */}
        {/*  4 Refuse par hote */}
        <div className="actions-btn | flrow liner btw">
          {cardReservationInfo.refundedAt ? (
            <button
              onClick={() =>
                handleOpenReservationPanel(
                  cardReservationInfo,
                  true,
                  false,
                  false,
                  true
                )
              }
              className="see-details-btn | btn sd-btn"
            >
              <span>{t("seeDetailsTxt")}</span>
              <ChevronRightSimple stroke="var(--shade-black)" strokeWidth="2" />
            </button>
          ) : statusPay == "waiting" ? (
            hoteMode ? (
              <>
                <button
                  onClick={() =>
                    handleOpenReservationPanel(cardReservationInfo, true, true)
                  }
                  className="ach-btn | btn"
                >
                  <span>{t("acceptBtnTxt")}</span>
                </button>
                <button
                  onClick={() =>
                    handleOpenReservationPanel(cardReservationInfo, false)
                  }
                  className="cch-btn | btn"
                >
                  <span>{t("refuseBtnTxt")}</span>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() =>
                    handleOpenReservationPanel(cardReservationInfo)
                  }
                  className="cancel-btn | btn pry-btn"
                >
                  <span>{t("refuseBtnTxtCancel")}</span>
                </button>
              </>
            )
          ) : (statusPay == "accepted" || statusPay == "payment_processing") &&
            hoteMode == false ? (
            <>
              <button
                disabled={statusPay == "payment_processing"}
                onClick={() =>
                  statusPay != "payment_processing" &&
                  handleOpenReservationPanel(cardReservationInfo)
                }
                className="cancel-btn | btn pry-btn"
              >
                <span>{t("cancelTxtPaid")}</span>
              </button>
              <button
                disabled={statusPay == "payment_processing"}
                onClick={handleGoToResidence}
                className="confirm-btn | btn pry-btn"
              >
                <span>{t("passToPaidTxt")}</span>
              </button>
            </>
          ) : statusPay == "planified" && !cardReservationInfo.refundAskedAt ? (
            <>
              {!hoteMode && (
                <button
                  onClick={() =>
                    handleOpenReservationPanel(cardReservationInfo, false, true)
                  }
                  className="cancel-btn | btn pry-btn"
                >
                  <span>Annuler</span>
                </button>
              )}

              <button
                onClick={() =>
                  handleOpenReservationPanel(cardReservationInfo, true)
                }
                className="see-details-btn | btn sd-btn"
              >
                <span>{t("seeDetailsTxt")}</span>
                <ChevronRightSimple
                  stroke="var(--shade-black)"
                  strokeWidth="2"
                />
              </button>
            </>
          ) : cardReservationInfo.refundAskedAt && hoteMode == false ? (
            <>
              <button
                onClick={() =>
                  handleOpenReservationPanel(
                    cardReservationInfo,
                    false,
                    false,
                    true,
                    false
                  )
                }
                className="cancel-btn | btn pry-btn"
              >
                <span>Annuler la demande</span>
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() =>
                  handleOpenReservationPanel(cardReservationInfo, true)
                }
                className="see-details-btn | btn sd-btn"
              >
                <span>{t("seeDetailsTxt")}</span>
                <ChevronRightSimple
                  stroke="var(--shade-black)"
                  strokeWidth="2"
                />
              </button>
            </>
          )}
          {}
        </div>
      </div>
    </div>
  );
}
