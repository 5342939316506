import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import { NotificationService } from "../../services/Notification.service";
import { UserPreferences } from "../../store/UserPreferences";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import "../../styles/components/choiceLanguageDeviseModal.css";
import "../../styles/components/signalementModal.css";

export default function ChoiceLanguageDeviseModal({}) {
  const userDevise = UserPreferences((state) => state.devise);
  const userLanguage = UserPreferences((state) => state.language);
  const currencies = useStoreCurrencies((state) => state.currencies);
  const handleChangeDevise = UserPreferences(
    (state) => state.handleChangeDevise
  );
  const handleChangeShowDevise = UserPreferences(
    (state) => state.handleChangeShowDevise
  );
  const handleChangeLanguage = UserPreferences(
    (state) => state.handleChangeLanguage
  );
  const [currentMenu, setCurrentMenu] = useState(1);

  const { t } = useTranslation();
  return (
    <div className="signalement-modal choice-user | flcolm">
      <div className="choose-menu | flrow liner maxwidth">
        <button
          onClick={() => setCurrentMenu(1)}
          className={`btn ${currentMenu == 1 ? "selected" : ""}`}
        >
          <span>{t("selectLangueLabel")}</span>
        </button>
        <button
          onClick={() => setCurrentMenu(2)}
          className={`btn ${currentMenu == 2 ? "selected" : ""}`}
        >
          <span>{t("selectDeviseLabel")}</span>
        </button>
      </div>

      <div
        className={`contents side-scroll ${
          currentMenu == 2 ? "show-devise" : ""
        } | flrow`}
        style={{ height: currentMenu == 1 ? 136 : 198 }}
      >
        <div className="selectors | flcolm">
          <button
            onClick={() => {
              i18n.changeLanguage("fr");
              handleChangeLanguage("fr-FR");
            }}
            className="select-btn | btn btw rlve"
          >
            <span>{t("francaisName")} </span>
            <div className="selector-circle rlve">
              {userLanguage == "fr-FR" && (
                <span className="center-absolute"></span>
              )}
            </div>
          </button>
          <button
            style={{
              background: "var(--gray-200)",
              border: "1px solid var(--gray-200)",
            }}
            onClick={() => {
              NotificationService.addToQueue(
                1,
                "Fonctionnalité bientôt disponible"
              );
              return;
              i18n.changeLanguage("en");
              handleChangeLanguage("en-EN");
            }}
            className="select-btn | btn btw rlve"
          >
            <span>{t("anglaisName")}</span>
            <div className="selector-circle rlve">
              {userLanguage == "en-EN" && (
                <span className="center-absolute"></span>
              )}
            </div>
          </button>
        </div>
        <div className="selectors | flcolm">
          {currencies.currencies.map((currency) => (
            <button
              key={currency.id}
              style={
                currency.id > 1
                  ? {
                      background: "var(--gray-200)",
                      border: "1px solid var(--gray-200)",
                    }
                  : {}
              }
              onClick={() => {
                if (currency.id > 1) {
                  NotificationService.addToQueue(
                    1,
                    "Fonctionnalité bientôt disponible"
                  );
                  return;
                }
                handleChangeShowDevise(currency.code);
                handleChangeDevise(currency.name);
              }}
              className="select-btn | btn btw rlve"
            >
              <span>{`(${currency.code}) ${currency.name}`}</span>
              <div className="selector-circle rlve">
                {userDevise == currency.name && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
