/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/cardResi.css";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Heart, Star } from "../../images/icons/JsxIcons";
import Residence from "../../models/residence";
import { NotificationService } from "../../services/Notification.service";
import { post, remove } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  checkDateJust,
  formatStringToOneUpperCase,
  handlePreviewPrice,
} from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";

export default function CardResidence({
  residence = new Residence(),
  updateFavorisList = null,
  favorisPage = false,
  lastIdTouched = null,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const devise = UserPreferences((state) => state.devise);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const abortController = new AbortController();
  const userData = useStoreUserManager((state) => state.user);
  const favorisUser = useStoreUserManager((state) => state.favorites);
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  const [animMe, setAnimMe] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const cancelToken = useRef(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const [isFav, setIsFav] = useState(false);
  const [baseFav, setBaseFav] = useState(false);

  // const [dataFav, loadingFavData, errorFavData, actionFetch] =
  //   useGetDelayRequest({
  //     route: requestEndpoint.favoritesAdd,
  //     loadingTime: 0,
  //     testing: false,
  //     log: true,
  //   });

  useEffect(() => {
    let find = false;

    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == residence.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser]);

  const handleFavoris = async (e) => {
    e.stopPropagation();
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }

    document.body.focus();
    setLoadingFav(true);
    if (userData?.connected == false) {
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("youNeedConnect1Txt"));
      return;
    }
    let favState = !isFav;
    setIsFav(favState);

    if (cancelToken.current) {
      cancelToken.current.cancel(
        "Requête annulée car une nouvelle a été déclenchée."
      );
    }

    cancelToken.current = axios.CancelToken.source();
    let response = null;
    try {
      if (favState) {
        response = await post(
          requestEndpoint.favoritesAdd,
          {
            residenceId: residence.id,
          },
          {},
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        // console.log(response);
        NotificationService.addToQueue(0, t("addFavorisDone"));
        setIsFav(true);
        setBaseFav(true);
      } else {
        const url =
          `${requestEndpoint.favoritesDelete}?residenceId=` + residence.id;
        response = await remove(
          url,
          {},
          {},
          { cancelToken: cancelToken.current }
        );
        // console.log(response);

        setIsFav(false);
        setBaseFav(false);
      }
      let newFav = [];
      if (favState) {
        favorisUser.map((fav) => {
          newFav.push(fav);
        });
        newFav.push(residence.id);
      } else {
        favorisUser.map((fav) => {
          if (fav != residence.id) newFav.push(fav);
        });
      }
      handleUpdateFavorisUser(newFav);
      if (updateFavorisList) updateFavorisList(residence.id);
      setLoadingFav(false);
    } catch (error) {
      // console.log(error?.response?.status);
      let status = error?.response?.status;
      if (status == 401)
        NotificationService.addToQueue(1, t("errorSendFavorite"));
      if (axios.isCancel(error)) return;
      setIsFav(baseFav);
    }
  };
  useEffect(() => {
    if (loadingFav) return;
    let find = false;
    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == residence.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser, loadingFav]);

  const [loadingPreview, setLoadingPreview] = useState(true);
  const [reductionApply, setReductionApply] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const storeParametersSender = useStoreManager(
    (state) => state.searchStoreParams
  );

  useEffect(() => {
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    // let startDate = storeParametersSender.startDate;
    // let endDate = storeParametersSender.endDate;
    let startDate = new Date();
    let endDate = new Date(new Date().getTime() + 86400000);
    let personneNumber = storeParametersSender.numPeople || 1;
    if (!checkDateJust(startDate) && !checkDateJust(endDate)) {
      //start date and end date are not valid, donc start date sera egale a la date du jour en iso string et end date sera egale a la date du jour plus 1 jour
      startDate = new Date();
      endDate = new Date(new Date().getTime() + 86400000);
    }
    startDate = startDate.toISOString();
    endDate = endDate.toISOString();
    //mettre l'heure a 00:00:00 pour les deux dates
    startDate = new Date(startDate);
    startDate.setHours(0, 0, 0, 0);
    startDate = startDate.toISOString();
    endDate = new Date(endDate);
    endDate.setHours(0, 0, 0, 0);
    endDate = endDate.toISOString();

    //genere un timeout entre 30 et 100
    let timeout = Math.floor(Math.random() * (45 - 30 + 1));
    let timer = setTimeout(() => {
      try {
        handlePreviewPrice(
          startDate,
          endDate,
          // personneNumber,
          1,
          residence.id,
          false
        )
          .then((data) => {
            subTotalPriceN = data.subTotalPrice;
            fraisPriceN = data.fraisPrice;
            totalPriceResidenceN = data.totalPriceResidence;
            setTotalPrice(totalPriceResidenceN);
            setReductionApply(true);
            setLoadingPreview(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log("error", error);
      }
    }, timeout);
    setTimeoutId(timer);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [storeParametersSender]);
  const [hrefUrl, setHrefUrl] = useState("");
  const location = useLocation();
  const handleGoToResidencepage = async () => {
    const searchStoreParams = useStoreManager.getState().searchStoreParams;
    //on verifie si le pathname contient /store
    if (!location.pathname.includes("/store")) {
      useStorePageManager.getState().handleUdpateStoreMap(false);
      setHrefUrl(
        `/residence/${
          residence.slug
        }?startDate=${""}&endDate=${""}&nbrPersonne=${1}`
      );
      return;
    }
    let startDateUrl = checkDateJust(searchStoreParams.startDate)
      ? searchStoreParams.startDate
      : "";
    let endDateUrl = checkDateJust(searchStoreParams.endDate)
      ? searchStoreParams.endDate
      : "";
    if (startDateUrl) {
      startDateUrl = new Date(startDateUrl).toISOString();
    }
    if (endDateUrl) {
      endDateUrl = new Date(endDateUrl).toISOString();
    }
    setHrefUrl(
      `/residence/${
        residence.slug
      }?startDate=${startDateUrl}&endDate=${endDateUrl}&nbrPersonne=${
        searchStoreParams.numPeople ?? 1
      }`
    );
    return;
  };
  useEffect(() => {
    handleGoToResidencepage();
  }, []);
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    if (!residence) return;
    let priceBase = 0;
    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = residence.price;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      priceBase = residence.priceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      priceBase = residence.priceDollars;
    }
    setCurrentPrice(priceBase);
  }, [residence, devise]);
  // console.log(
  //   "residence",
  //   `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
  // );
  // console.log("residence", residence);
  let addreaa = residence?.address ?? residence.location;
  const [nameToShow, setNameToShow] = useState("");
  useEffect(() => {
    if (!residence) return;
    let name = formatStringToOneUpperCase(residence.name.toLocaleLowerCase());
    setNameToShow(name);
  }, [residence]);

  const [randomPixel, setRandomPixel] = useState(100);
  //get a random number between 100 and 250
  useEffect(() => {
    let min = 125;
    let max = 300;
    let random = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomPixel(random);
    let timer = null;
    let timer2 = null;
    if (lastIdTouched) {
      if (lastIdTouched == residence.id) {
        let element = document.getElementById(`card-resi-${residence.id}`);
        if (element) {
          setAnimMe(true);
          timer = setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 100);
          timer2 = setTimeout(() => {
            setAnimMe(false);
          }, 750);
        }
        useStorePageManager.getState().handleUpdateLastResidenceTouched(null);
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (timer2) clearTimeout(timer2);
    };
  }, []);

  return (
    <div className={`card-resi-content rlve ${animMe ? "anim-me-card" : ""}`}>
      <Link
        to={hrefUrl}
        id={`card-resi-${residence.id}`}
        onClick={handleGoToResidencepage}
        className={`card-resi ${
          favorisPage && baseFav != isFav ? "load-not-touch" : ""
        } | rlve flcolm`}
      >
        <div className="top-card | rlve">
          <img
            src={
              residence.medias
                ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
                : residence.thumb
            }
            alt="img-residence"
            onLoad={handleImageLoad}
            style={{ display: imageLoaded ? "inline" : "none" }}
          />
          {!imageLoaded && (
            <div
              style={{ width: "101%", height: "100%" }}
              className="skeleton center-absolute"
            ></div>
          )}
        </div>
        <div className="bot-val | flcolm addgap8">
          <label>{addreaa}</label>
          <h3>{nameToShow}</h3>
          <div className="price-note | flrow addgap16 liner">
            {!loadingPreview ? (
              <span>
                {handleFormatToLocalString(totalPrice, language)} {showDevise}{" "}
                {t("byNight")}
              </span>
            ) : (
              <span
                className="skeleton span-skeleton"
                style={{
                  height: "20px",
                  width: randomPixel,
                }}
              ></span>
            )}

            <hr />
            <div className="star-resi | flrow liner">
              <span>
                {residence.stars
                  ? parseFloat(residence.stars?.toString()).toFixed(1)
                  : t("noneTxtNote")}
              </span>
              <Star
                color={residence.stars ? "#FACC15" : "var(--shade-black)"}
              />
            </div>
          </div>
        </div>
      </Link>
      <button className="btn-fav" onClick={handleFavoris}>
        <Heart
          color={isFav ? "var(--destructive-500)" : "transparent"}
          stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
        />
      </button>
    </div>
  );
}
