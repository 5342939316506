import { create } from "zustand";

export const useStoreAllStaticData = create((set, get) => ({
  typeResi: [],
  versementMethod: [],
  handleUpdateVersementMethod: (payload) => {
    set(() => ({ versementMethod: payload }));
  },
  handleUpdateTypeResi: (payload) => {
    set(() => ({ typeResi: payload }));
  },
}));
