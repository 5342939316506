import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import flagIcon from "../../images/icons/flag-03.svg";
import { NotificationService } from "../../services/Notification.service";
import {
  CheckCompareRef,
  GetWidthFromRef,
  useObserveToYDirect,
} from "../../services/useCurrentScrollY";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { checkDateJust, handlePreviewPrice } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/reservationPanel.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import AddLessDiv from "../molecules/AddLessDiv";
import { InputDatePicker } from "./InputDatePicker";
import SignalementModal from "./SignalementModal";
export default function ReservationPanel({ residence = null }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const refReservation = document.getElementById("main-reservation-hero");
  const [blockedReservation, setBlockedReservation] = useState([]);
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = UserPreferences((state) => state.language);

  const reservationParams = useStoreManager((state) => state.reservationParams);
  const searchStoreParams = useStoreManager((state) => state.searchStoreParams);
  const handleUpdateReservationParameters = useStoreManager(
    (state) => state.handleUpdateReservationParameters
  );
  //on verifie dans le navigate si le state est present on le console.log
  const location = useLocation();
  //on recupere les parametres de la recherche
  let searchUrlParams = new URLSearchParams(location.search);
  let searchParams = {};
  for (let [key, value] of searchUrlParams) {
    searchParams[key] = value;
  }
  let startDateUrl = useMemo(() => searchParams.startDate ?? null);
  let endDateUrl = useMemo(() => searchParams.endDate ?? null);
  let numPeopleUrl = useMemo(() => searchParams.nbrPersonne ?? null);
  if (numPeopleUrl) {
    numPeopleUrl = parseInt(numPeopleUrl);
  }

  const [startDate, setStartDate] = useState(
    checkDateJust(startDateUrl)
      ? new Date(startDateUrl)
      : searchStoreParams?.location?.toString().trim().length > 0
      ? searchStoreParams?.startDate ?? null
      : null
  );
  const [endDate, setEndDate] = useState(
    checkDateJust(endDateUrl)
      ? new Date(endDateUrl)
      : searchStoreParams?.location?.toString().trim().length > 0
      ? searchStoreParams?.endDate ?? null
      : null
  );

  const [personneNumber, setPersonneNumber] = useState(
    numPeopleUrl ? numPeopleUrl : searchStoreParams?.numPeople ?? 1
  );

  const [timeoutId, setTimeoutId] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [fraisPrice, setFraisPrice] = useState(0);

  const { state, bottomSeuil, currentBottom } = useObserveToYDirect(
    refReservation,
    175
  );
  const { stateCompare, refFromTop1, refFromTop2 } = CheckCompareRef(
    "reservation-panel-end",
    "reservation-panel"
  );

  const [isOpen, toggle] = useState(false);
  const { offsetWidth } = GetWidthFromRef("reservation-panel-end") ?? 0;

  const [currentPrice, setCurrentPrice] = useState(0);

  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );

  function generateDateRangeArray(fromDate, toDate) {
    // Format de retour : [new Date(2023, 11, 20), ...]
    let dateArray = [];
    let currentDate = new Date(fromDate);
    let stopDate = new Date(toDate);
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate.getTime())); // Utilisez getTime() pour obtenir une copie indépendante
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  useEffect(() => {
    if (!residence) return;

    let blockedReservation = [];
    residence.bookings.forEach((reservation) => {
      if (
        reservation.status === "planified" ||
        reservation.status === "progressing"
      ) {
        //faire -1jour au toDate
        let toDate = new Date(reservation.toDate);
        toDate.setDate(toDate.getDate() - 1);
        reservation.toDate = toDate;
      }
      blockedReservation.push(
        ...generateDateRangeArray(reservation.fromDate, reservation.toDate)
      );
    });
    setBlockedReservation(blockedReservation);
    let priceBase = 0;
    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = residence.price;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      priceBase = residence.priceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      priceBase = residence.priceDollars;
    }
    setCurrentPrice(priceBase);
  }, [startDate, endDate, residence, devise]);

  const handleGoToReservation = () => {
    handleUpdateBlockScreen(true);
    let startDate2 = new Date(startDate ?? new Date());
    let endDate2 = new Date(endDate ?? new Date());
    let year = startDate2.getFullYear();
    let month = (startDate2.getMonth() + 1).toString().padStart(2, "0");
    let day = startDate2.getDate().toString().padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;
    startDate2 = formattedDate;
    year = endDate2.getFullYear();
    month = (endDate2.getMonth() + 1).toString().padStart(2, "0");
    day = endDate2.getDate().toString().padStart(2, "0");
    formattedDate = `${year}-${month}-${day}`;
    endDate2 = formattedDate;
    // console.log("startDate2", startDate2);
    // console.log("endDate2", endDate2);

    let timer = setTimeout(() => {
      handleUpdateReservationParameters({
        residenceId: residence.id,
        residence: residence,
        lastPage: "/residence/" + residence.slug,
        startDate: new Date(startDate2),
        endDate: new Date(endDate2),
        nbrPersonne: personneNumber,
      });
      setTimeout(() => {
        handleUpdateBlockScreen(false);
        navigate("/reservation/0");
      }, 250);
    }, 1000);
    setTimeoutId(timer);
  };
  const userData = useStoreUserManager((state) => state.user);
  const handleAskReservation = () => {
    if (
      personneNumber == 0 ||
      startDate == new Date() ||
      endDate == new Date()
    ) {
      NotificationService.addToQueue(1, "Entrez d'abord les ci-dessus");
      return;
    }
    if (userData.connected == false) {
      NotificationService.addToQueue(1, t("youNeedConnect1Txt"));
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      return;
    }
    handleGoToReservation();
  };
  const [daySave, setDaySave] = useState(1);
  const [priceSaveBase, setPriceSaveBase] = useState(0);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);

  const [timerCount, setTimerCount] = useState();
  const handleCheckIfValid = useCallback(() => {
    let timer = setTimeout(() => {
      if (startDate == null && endDate == null) {
        setSubtotalPrice(0);
        setFraisPrice(0);
        setTotalPrice(0);
        setDaySave(0);
        setReductionApply(false);
        setLoadingPreview(false);
        return true;
      }
    }, 100);
    setTimerCount(timer);
  }, [startDate, endDate]);
  useEffect(() => {
    handleCheckIfValid();
    //mettre a jour les parametres de l'url dans l'url de la page
    if (!residence) return;
    let addUrls = [];
    checkDateJust(startDate) &&
      addUrls.push("startDate=" + startDate.toISOString().split("T")[0]);
    checkDateJust(endDate) &&
      addUrls.push("endDate=" + endDate.toISOString().split("T")[0]);
    personneNumber > 1 && addUrls.push("nbrPersonne=" + personneNumber);
    let url = `/residence/${residence.slug}`;
    if (addUrls.length > 0) {
      url += "?" + addUrls.join("&");
    }
    navigate(url, { replace: true });
    return () => clearTimeout(timerCount);
  }, [startDate, endDate, personneNumber, residence]);
  useEffect(() => {
    if (residence) {
      const startMoment = moment(startDate).startOf("day");
      const endMoment = moment(endDate).endOf("day");
      let daysDifference = endMoment.diff(startMoment, "days");
      let {
        subTotalPriceN = 0,
        fraisPriceN = 0,
        totalPriceResidenceN = 0,
      } = {};
      let priceBaseSecond = 0;
      // console.log(devise);
      if (devise.toLocaleLowerCase() == "Franc CFA".toLocaleLowerCase()) {
        priceBaseSecond = parseInt(residence.price);
      } else if (devise.toLocaleLowerCase() == "Euro".toLocaleLowerCase()) {
        priceBaseSecond = parseInt(residence.priceEuro);
      } else if (
        devise.toLocaleLowerCase() == "Dollar US".toLocaleLowerCase()
      ) {
        priceBaseSecond = parseInt(residence.priceDollars);
      }
      setPriceSaveBase(priceBaseSecond);
      if (checkDateJust(startDate) && checkDateJust(endDate)) {
        if (handleCheckIfValid()) return;
        try {
          if (endDate < startDate) {
            setSubtotalPrice(0);
            setFraisPrice(0);
            setTotalPrice(0);
            setDaySave(0);
            setReductionApply(false);
            setLoadingPreview(false);
            return;
          }
        } catch (error) {
          setSubtotalPrice(0);
          setFraisPrice(0);
          setTotalPrice(0);
          setDaySave(0);
          setReductionApply(false);
          setLoadingPreview(false);
          return;
        }

        setLoadingPreview(true);
        handlePreviewPrice(startDate, endDate, personneNumber, residence.id)
          .then((data) => {
            subTotalPriceN = data.subTotalPrice;
            fraisPriceN = data.fraisPrice;
            totalPriceResidenceN = data.totalPriceResidence;
            setSubtotalPrice(subTotalPriceN);
            setFraisPrice(fraisPriceN);
            setTotalPrice(totalPriceResidenceN);
            setDaySave(daysDifference);
            setReductionApply(true);
            setLoadingPreview(false);
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      setReductionApply(false);

      let fraisPrice = 0;

      if (daysDifference <= 0) daysDifference = 1;
      if (!daysDifference) daysDifference = 0;
      // console.log("daysDifference", daysDifference);
      let priceBase = 0;
      // console.log(devise);
      if (devise.toLocaleLowerCase() == "Franc CFA".toLocaleLowerCase()) {
        priceBase = parseInt(residence.price);
      } else if (devise.toLocaleLowerCase() == "Euro".toLocaleLowerCase()) {
        priceBase = parseInt(residence.priceEuro);
      } else if (
        devise.toLocaleLowerCase() == "Dollar US".toLocaleLowerCase()
      ) {
        priceBase = parseInt(residence.priceDollars);
      }
      setPriceSaveBase(priceBase);
      let totalPriceResidence = 0;
      totalPriceResidence = Math.round(daysDifference * priceBase);
      fraisPrice = Math.round((totalPriceResidence * 7) / 100);
      if (fraisPrice.toString().includes(".")) {
        fraisPrice = fraisPrice.toFixed(2);
      }
      totalPriceResidence = totalPriceResidence + fraisPrice;
      if (totalPriceResidence.toString().includes(".")) {
        totalPriceResidence = totalPriceResidence.toFixed(2);
      }
      setDaySave(daysDifference);
      setFraisPrice(fraisPrice);
      setTotalPrice(totalPriceResidence);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [startDate, endDate, residence, devise]);
  useEffect(() => {
    // console.log("reservationParams", reservationParams);
  }, [reservationParams]);
  return residence ? (
    <>
      <ContainerPersoModal isOpen={isOpen} toggle={toggle}>
        <SignalementModal data={residence} />
      </ContainerPersoModal>
      <article
        id="reservation-panel"
        className={`reservation-panel ${
          state
            ? bottomSeuil
              ? "fixed-clamp-reservation-bottom"
              : "fixed-clamp-reservation"
            : ""
        } ${
          refFromTop1 <= 225 ? "fixed-clamp-reservation-bottom" : ""
        } | flcolm`}
        style={{ width: offsetWidth }}
      >
        <div className="contains-reservation | flcolm">
          <div className="price-show | flrow liner rlve">
            <span className="big-price">
              {handleFormatToLocalString(currentPrice, language)} {showDevise}
            </span>
            <span>{t("byNight")}</span>
          </div>
          <div className="date-info | flcolm">
            <label>{t("chooseDateTxt")}</label>
            <span>{t("nbDate")}</span>
          </div>
          <div className="options-select | flcolm">
            <div className="date-select | flrow rlve">
              <InputDatePicker
                value={startDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                title="Arrivée"
                type="start"
                placeholder={"date"}
                nbrMonth={1}
                className="date-part date-header residence-article"
                confirmSearchParameters={reservationParams}
                direct={true}
                excludeDatesValues={blockedReservation}
              />
              <hr />
              <InputDatePicker
                value={endDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                title="Départ"
                type="end"
                placeholder={"date"}
                nbrMonth={1}
                className="date-part date-header residence-article"
                confirmSearchParameters={reservationParams}
                direct={true}
                excludeDatesValues={blockedReservation}
              />
            </div>
            <AddLessDiv
              min={1}
              max={residence.maxPeople}
              classname="pers-nbr"
              title={t("persPlaceholder")}
              setData={setPersonneNumber}
              data={personneNumber}
              baseData={numPeopleUrl ?? 1}
            />
          </div>
          <div className="commande-btn | flcolm liner">
            <button
              // disabled={
              //   personneNumber == 0 || startDate == null || endDate == null
              // }
              style={
                personneNumber == 0 || startDate == null || endDate == null
                  ? {
                      opacity: 1,
                      background: "var(--gray-500)",
                      cursor: "not-allowed",
                    }
                  : {}
              }
              onClick={() =>
                personneNumber == 0 || startDate == null || endDate == null
                  ? NotificationService.addToQueue(
                      1,
                      "Remplissez les champs indiquant votre date d'arrivée et de départ"
                    )
                  : handleAskReservation()
              }
              className="btn pry-btn"
            >
              <span>{t("reservationAskTitle")}</span>
            </button>
            <span>{t("infoReservationTxt")}</span>
          </div>
          <div className="price-calcul | flcolm addgap8">
            {/* {(personneNumber == 0 || startDate == null || endDate == null)
                  ?"":} */}

            {reductionApply ? (
              <div
                style={{ padding: 0, paddingTop: 16 }}
                className="total-info | flrow liner btw"
              >
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  {handleFormatToLocalString(priceSaveBase, language)}{" "}
                  {showDevise} X {daySave}
                  {` Nuit${daySave > 1 ? "s" : ""}`}
                </span>
                {/* <span
                  className="flash-me"
                  style={{
                    fontSize: 12,
                  }}
                >
                  Sous total
                </span> */}
                {loadingPreview ? (
                  <span
                    style={{
                      height: 20,
                      width: "50%",
                    }}
                    className="skeleton span-skeleton"
                  ></span>
                ) : (
                  <span className="flash-me" style={{ fontSize: 13 }}>
                    {handleFormatToLocalString(subtotalPrice, language) +
                      " " +
                      showDevise}
                  </span>
                )}
              </div>
            ) : (
              <div
                style={{ padding: 0, paddingTop: 16 }}
                className="total-info | flrow liner btw"
              >
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  {handleFormatToLocalString(priceSaveBase, language)}{" "}
                  {showDevise} X {daySave}
                  {` Nuit${daySave > 1 ? "s" : ""}`}
                </span>

                <span style={{ fontSize: 13 }}>
                  {handleFormatToLocalString(
                    priceSaveBase * daySave,
                    language
                  ) +
                    " " +
                    showDevise}
                </span>
              </div>
            )}

            <div
              style={{ padding: 0, paddingTop: 16 }}
              className="total-info | flrow liner btw"
            >
              <span>{t("feeOfService")}</span>
              {loadingPreview ? (
                <span
                  style={{
                    height: 20,
                    width: "40%",
                  }}
                  className="skeleton span-skeleton"
                ></span>
              ) : (
                <span style={{ fontSize: 14, color: "var(--destructive-500)" }}>
                  {handleFormatToLocalString(fraisPrice, language) +
                    " " +
                    showDevise}
                </span>
              )}
            </div>
            <div
              style={{ padding: 0, borderTop: "none" }}
              className="total-info | flrow liner btw"
            >
              <span>Total</span>
              {loadingPreview ? (
                <span
                  style={{
                    height: 20,
                    width: "60%",
                  }}
                  className="skeleton span-skeleton"
                ></span>
              ) : (
                <span>
                  {handleFormatToLocalString(totalPrice, language) +
                    " " +
                    showDevise}
                </span>
              )}
            </div>
          </div>
        </div>
        <button onClick={() => toggle(true)} className="signal-resi | btn">
          <img src={flagIcon} alt="flag-icon" />
          <span>{t("signalAnnonceTxt")}</span>
        </button>
      </article>
      <article
        id="reservation-panel-end"
        className={`reservation-panel-bottom`}
      >
        <div className="contains-reservation"></div>
        <button onClick={() => toggle(true)} className="signal-resi | btn">
          <img src={flagIcon} alt="flag-icon" />
          <span>{t("signalAnnonceTxt")}</span>
        </button>
      </article>
    </>
  ) : (
    <div id="reservation-panel" className={`waiting-load flcolm`}>
      <div className="faker-contains-load skeleton"></div>
      <span
        className="skeleton span-skeleton"
        style={{
          width: "50%",
          height: 16,
          alignSelf: "center",
          marginTop: 16,
        }}
      ></span>
    </div>
  );
}
