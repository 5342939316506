import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/galerieResidence.css";
import crossIcon from "../../images/icons/x-close.svg";
import shareIcon from "../../images/icons/share-06.svg";
import {
  ChevronRightSimple,
  Heart,
  ShareIcon,
} from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import axios from "axios";
import { useStorePageManager } from "../../store/PageManage";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ShareModal from "./ShareModal";
import { useTranslation } from "react-i18next";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
export default function GalerieResidence({
  residence,
  handleOpenGalerie,
  isFav,
  handleFavoris,
}) {
  const { t } = useTranslation();
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseGalerie = () => {
    handleUpdateDesactiveHeader(false);
    handleUpdateBodyOverflow(true);
    handleOpenGalerie(false);
  };
  const [openShareModal, setOpenShareModal] = useState(false);

  useEffect(() => {
    handleUpdateDesactiveHeader(true);
    handleUpdateBodyOverflow(false);
    return () => {
      handleUpdateDesactiveHeader(false);
    };
  }, []);

  const [imagesShow, setImagesShow] = useState([<></>]);
  const [allImgsJustForShow, setAllImgsJusteOne] = useState(null);
  const handleSelectThisImage = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
    // console.log("open", isOpen);
  };
  useEffect(() => {
    if (!residence) return;

    let newTabImg = [];
    let count = 0;
    let oneRow = [];
    let newAllImages = [];
    if (residence.medias.length == 0) return;
    // const allImagesJust = residence.medias.map((photo, index) => {
    //   let newPhoto = {
    //     src: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${photo.filename}`,
    //     alt: "image-" + residence.name + "-" + index + 1,
    //   };
    //   return newPhoto;
    // });
    residence.medias.map((photo, index) => {
      count++;
      if (oneRow.length > 1) {
        if (newTabImg.length == 0) {
          newTabImg.push(
            <div className="one-row | flrow addgap16">{oneRow.reverse()}</div>
          );
        } else
          newTabImg.push(
            <div className="one-row | flrow addgap16">
              {index % 3 == 0 && index != 2 ? oneRow.reverse() : oneRow}
            </div>
          );
        oneRow = [];
      }
      let newPhoto = {
        src: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${photo.filename}`,
        alt: "image-" + residence.name + "-" + index + 1,
      };
      newAllImages.push(newPhoto);
      oneRow.push(
        <div
          onClick={() => handleSelectThisImage(index)}
          key={"photo-" + index}
          className={`${count % 2 == 0 ? "full" : ""} rlve normal-div`}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${photo.filename}`}
            alt={"photo-" + index}
          />
        </div>
      );
      if (
        count > 1 &&
        count % 5 == 0 &&
        count != residence.medias.length - 1 &&
        count < residence.medias.length
      ) {
        newTabImg.push(
          <div
            className="one-row only-one
           | flrow addgap16"
          >
            {oneRow}
          </div>
        );
        oneRow = [];
      } else if (count == residence.medias.length) {
        newTabImg.push(
          <div
            className="one-row only-one
           | flrow addgap16"
          >
            {(count - 1) % 10 != 0 ? oneRow.reverse() : oneRow}
          </div>
        );
      }
    });
    //inverser la position avec la precedente de la première images et la deuxième
    let firstImg = newAllImages[0];
    let secondImg = newAllImages[1];
    // newAllImages[0] = secondImg
    // newAllImages[1] = firstImg
    setAllImgsJusteOne(newAllImages);
    setImagesShow(newTabImg);
  }, [residence]);

  const handleNextImage = () => {
    if (currentImageIndex == allImgsJustForShow.length - 1) return;
    setCurrentIndex(currentImageIndex + 1);
  };
  const handlePrevImage = () => {
    if (currentImageIndex == 0) return;
    setCurrentIndex(currentImageIndex - 1);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        switch (event.key) {
          case "ArrowLeft":
            handlePrevImage();
            break;
          case "ArrowRight":
            handleNextImage();
            break;
          case "Escape":
            setIsOpen(false);
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handlePrevImage, handleNextImage]);
  if (!residence) return;

  return (
    <>
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={"Partager"}
        isOpen={openShareModal}
        toggle={setOpenShareModal}
      >
        <ShareModal data={residence} />
      </ContainerPersoModal>
      <div className="galerie-residence | flcolm">
        <div className="header-galerie-residence | flrow btw liner max-container maxwidth">
          <button onClick={handleCloseGalerie} className="close-galerie | btn">
            <img
              style={{
                width: 16,
                height: 16,
              }}
              src={crossIcon}
              alt="icon-residence"
            />
            <span>{t("galeriePhotoTxt")}</span>
          </button>
          <div className="others-actions-btn | flrow liner addgap16">
            <button onClick={() => setOpenShareModal(true)} className="btn">
              <ShareIcon />
              <span>{t("shareTxtGalerie")}</span>
            </button>
            <button onClick={handleFavoris} className="btn">
              <Heart
                color={isFav ? "var(--destructive-500)" : "transparent"}
                stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
              />
              <span>{t("saveTxtGalerie")}</span>
            </button>
          </div>
        </div>
        <div className="full-contains | flcolm max-container">{imagesShow}</div>
        {allImgsJustForShow && isOpen && (
          <>
            <div className="contains-photos-zoom">
              <div className="background-photo"></div>
              {allImgsJustForShow.map((photo, index) => (
                <div
                  className={`transform-photos ${
                    currentImageIndex < index
                      ? "next"
                      : currentImageIndex > index
                      ? "prev"
                      : ""
                  }`}
                  key={photo.alt + index}
                >
                  <TransformWrapper initialScale={1}>
                    <TransformComponent>
                      <img src={photo.src} alt={photo.alt} />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              ))}

              <button
                onClick={() => setIsOpen(false)}
                className="btn opt-btn close-btn"
              >
                <img src={crossIcon} alt="icon-residence" />
              </button>
              <div className="flrow addgap16 liner jcenter info-panel">
                <button
                  onClick={handlePrevImage}
                  disabled={currentImageIndex == 0}
                  className="btn pry-btn move-back"
                >
                  <ChevronRightSimple
                    color="var(--shade-white)"
                    stroke="var(--shade-white)"
                  />
                  <span style={{ fontWeight: 600 }}>Precedent</span>
                </button>
                <div className="flcolm jcenter liner">
                  <span>Photos</span>
                  <p className="-c">
                    {currentImageIndex + 1} / {allImgsJustForShow.length}
                  </p>
                </div>
                <button
                  onClick={handleNextImage}
                  disabled={currentImageIndex == allImgsJustForShow.length - 1}
                  className="btn pry-btn move-next"
                >
                  <span style={{ fontWeight: 600 }}>Suivant</span>{" "}
                  <ChevronRightSimple
                    color="var(--shade-white)"
                    stroke="var(--shade-white)"
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
