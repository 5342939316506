import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "../services/useFetchAction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import "../styles/pages_styles/reservationPaiement.css";

import ReservationRequestContent from "../components/biggest/ReservationRequestContent";
import HeaderReservation from "../components/cells/HeaderReservation";
import { NotificationService } from "../services/Notification.service";
import { useStoreManager } from "../store/StoreManage";
import { useStoreUserManager } from "../store/UserManage";

export default function ReservationPaiement() {
  const { id } = useParams() || null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openQuitModal, setOpenQuitModal] = useState(false);

  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const reservationParams = useStoreManager((state) => state.reservationParams);
  const userData = useStoreUserManager((state) => state.user);
  useEffect(() => {
    if (reservationParams.residenceId == null) {
      NotificationService.addToQueue(
        1,
        "Nous ne permettons pas un rechargement sur la page de paiement."
      );
      if (reservationParams.lastPage) {
        navigate(reservationParams.lastPage);
        return;
      } else navigate(-1);
      return;
    }
    handleUpdateStorePage(false);
    handleUpdateDesactiveHeader(true);
  }, []);

  const [data, loading, error] = useGetData({
    route: "/residence",
    dataFetch: { id },
    testing: true,
  });

  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  const reservationCard = reservationParams.reservation ?? null;
  useEffect(() => {
    if (userData == null) return;
    if (userData.connected) {
      if (reservationParams.residenceId == null) return;
      let residenceHostId = reservationParams.residence?.hostId ?? null;
      const startDate = reservationParams.startDate;
      const endDate = reservationParams.endDate;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      //on verifie si les dates sont valides en validant que startDate >= aujourdhui et si la date de fin est superieur a la date de debut de reservation
      if (startDate < today || endDate <= startDate) {
        NotificationService.addToQueue(
          1,
          "Les dates de réservation ne sont pas valides."
        );
        navigate(-1);
        return;
      }
      if (residenceHostId == userData.id) {
        NotificationService.addToQueue(
          1,
          "Vous ne pouvez pas réserver votre propre résidence."
        );
        navigate(-1);
        return;
      }
    }
  }, [reservationParams, userData]);
  // console.log("reservationCard", reservationCard);
  const dataResidence = {
    startDate: reservationParams.startDate,
    endDate: reservationParams.endDate,
    nbrPersonne: reservationParams.nbrPersonne,
    residenceId: reservationParams.residenceId,
    residencePrice: reservationParams.residence?.price,
    residencePriceEuro: reservationParams.residence?.priceEuro,
    residencePriceDollars: reservationParams.residence?.priceDollars,
    residenceName:
      reservationParams.residence?.title ?? reservationParams.residence?.name,
    residenceLocation:
      reservationParams.residence?.location ??
      reservationParams.residence?.address,
    residenceStars: reservationParams.residence?.stars ?? "N/A",
    residence: reservationParams.residence,
    residenceThumb:
      reservationParams.residence?.medias[0]?.filename ??
      `${process.env.REACT_APP_API_URL}/assets/home-banner.jpg`,
    annulationPercent1: 0,
    annulationPercent2: 50,
    annulationPercent3: 100,
    total: reservationCard?.total,
    fee: reservationCard?.fee,
    subtotal: reservationCard?.subtotal,
  };
  const [headerShow, setHeaderShow] = useState(false);
  if (reservationParams.residenceId == null) return <></>;
  return (
    <>
      <main className="reservation-paiement">
        <HeaderReservation
          reservationCard={reservationCard}
          setHeaderShow={setHeaderShow}
          t={t}
          setOpenQuitModal={setOpenQuitModal}
        />
        <ReservationRequestContent
          t={t}
          reservationCard={reservationCard}
          data={dataResidence}
          loading={loading}
          error={error}
          setOpenQuitModal={setOpenQuitModal}
          openQuitModal={openQuitModal}
          headerShow={headerShow}
        />
      </main>
    </>
  );
}
