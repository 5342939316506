import moment from "moment";
import React, { useEffect, useState } from "react";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { UserPreferences } from "../../store/UserPreferences";
import TileResidence from "./TileResidence";
import {
  checkDateJust,
  getJustDateFormat,
  handlePreviewPrice,
} from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { useStorePageManager } from "../../store/PageManage";

export default function ConfirmReservationPanelToGo({
  residence,
  startDate,
  endDate,
  t,
  nbrPersonne,
  handleUpdateReservationParameters,
}) {
  const devise = UserPreferences((state) => state.devise);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const [totalPrice, setTotalPrice] = useState(0);
  const [days, setDays] = useState(1);
  const [timeoutId, setTimeoutId] = useState();
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const navigate = useNavigate();

  const handleGoToReservation = () => {
    handleUpdateBlockScreen(true);
    handleUpdateBodyOverflow(true);
    handleUpdateReservationParameters({
      residenceId: residence.id,
      residence: residence,
      lastPage: "/residence/" + residence.slug,
      startDate,
      endDate,
      nbrPersonne: nbrPersonne,
    });
    let timer = setTimeout(() => {
      handleUpdateBlockScreen(false);
      navigate("/reservation/0");
    }, 1000);
    setTimeoutId(timer);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [fraisPrice, setFraisPrice] = useState(0);
  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  useEffect(() => {
    if (!residence) return;
    if (!startDate) return;
    if (!endDate) return;
    if (!nbrPersonne) return;
    const startMoment = moment(startDate).startOf("day");
    const endMoment = moment(endDate).endOf("day");
    let fraisPrice = 0;
    let daysDifference = endMoment.diff(startMoment, "days");
    if (daysDifference <= 0) daysDifference = 1;
    setDays(daysDifference);
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    if (checkDateJust(startDate) && checkDateJust(endDate)) {
      setLoadingPreview(true);
      handlePreviewPrice(startDate, endDate, nbrPersonne, residence.id).then(
        (data) => {
          subTotalPriceN = data.subTotalPrice;
          fraisPriceN = data.fraisPrice;
          totalPriceResidenceN = data.totalPriceResidence;
          setSubtotalPrice(subTotalPriceN);
          setFraisPrice(fraisPriceN);
          setTotalPrice(totalPriceResidenceN);
          setReductionApply(true);
          setLoadingPreview(false);
        }
      );
      return;
    }
    setReductionApply(false);

    let priceBase = 0;
    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = residence.price;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      // priceBase = residence.priceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      // priceBase = residence.priceDollars;
    }
    let totalPriceResidence = 0;
    totalPriceResidence = Math.round(daysDifference * priceBase);
    fraisPrice = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPrice.toString().includes(".")) {
      fraisPrice = fraisPrice.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPrice;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }

    // let nbrDay = moment(endDate)
    //   .startOf("day")
    //   .diff(moment(startDate).endOf("day"), "days");
    // nbrDay++;

    const total = priceBase * daysDifference;
    setTotalPrice(total);
  }, [
    residence,
    startDate,
    endDate,
    nbrPersonne,
    devise,
    language,
    showDevise,
  ]);
  return (
    <div className="confirm-reservation | flcolm jcenter liner">
      <div className="confirm-reservation-info | flcolm liner">
        <TileResidence residence={residence} />
        <div className="date-one | flcolm addgap8">
          <label>Date</label>
          <span>
            {getJustDateFormat(startDate, "D MMMM YYYY")} -{" "}
            {getJustDateFormat(endDate, "D MMMM YYYY")}
          </span>
          <span>
            {days} {"nuit"}
            {days > 1 ? "s" : ""}
          </span>
        </div>
        <div className="date-one | flcolm addgap8">
          <label>{t("personneNumber")}</label>
          <span>
            {nbrPersonne} {t("personneTxt")}
            {nbrPersonne > 1 ? "s" : ""}
          </span>
        </div>
        {/* <div className="date-one | flcolm addgap8">
          <label>{"Sous total"}</label>
          <span>
            {handleFormatToLocalString(subtotalPrice, language)} {showDevise}
          </span>
        </div>
        <div className="date-one | flcolm addgap8">
          <label>{"Frais"}</label>
          <span>
            {handleFormatToLocalString(fraisPrice, language)} {showDevise}
          </span>
        </div> */}
        {loadingPreview ? (
          <div className="date-one | flcolm addgap8 maxwidth">
            <span
              style={{
                height: "20px",
                width: "60%",
                maxWidth: "100%",
              }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{
                height: "20px",
                width: "50%",
              }}
              className="skeleton span-skeleton"
            ></span>
          </div>
        ) : (
          <div className="date-one | flcolm addgap8">
            <label>{"Prix total"}</label>
            <span>
              {handleFormatToLocalString(totalPrice, language)} {showDevise}
            </span>
          </div>
        )}
      </div>
      <div className="confirm-reservation-btns | flrow liner btw maxwidth">
        <button
          disabled={loadingPreview}
          onClick={handleGoToReservation}
          className="confirm-btn | btn pry-btn maxwidth"
        >
          {t("nextBtnTxt")}
        </button>
      </div>
    </div>
  );
}
