import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Star, Warning } from "../../images/icons/JsxIcons";
import { get } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  findVersementMethod,
  handleHideSomeNumberInRib,
  handleSepareContact,
} from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { UserPreferences } from "../../store/UserPreferences";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ModifVersement from "./profilPart/ModifVersement";

export default function ReservationModal({
  withRemboursement,
  reservationInfo,
  children,
  t,
  hoteMode = false,
  topInfo = true,
}) {
  const navigate = useNavigate();
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const getDate = (date) => {
    return moment(date).format("DD MMMM YYYY");
  };
  const showDevise = UserPreferences((state) => state.showDevise);
  const devise = UserPreferences((state) => state.devise);
  const language = UserPreferences((state) => state.language);

  let newValueSepared = 0;
  if (
    devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
  ) {
    newValueSepared = reservationInfo.total;
    if (hoteMode) {
      newValueSepared = reservationInfo.hostMoney;
    }
  } else if (
    devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
  ) {
    // newValueSepared = reservationInfo.priceEuro;
  } else if (
    devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
  ) {
    // newValueSepared = reservationInfo.priceDollars;
  }
  newValueSepared = handleFormatToLocalString(
    newValueSepared.toString(),
    language
  );
  const handleNavigateToResidence = () => {
    handleUpdateBodyOverflow(true);
    navigate(`/residence/${reservationInfo.residence.slug}`);
  };
  let motifiRefus = null;
  if (reservationInfo.status == "cancelled")
    switch (language) {
      case "fr-FR":
        motifiRefus = reservationInfo.cancelReason;
        break;
      default:
        motifiRefus = reservationInfo.cancelReasonEn;
        break;
    }
  else if (reservationInfo.status == "rejected") {
    switch (language) {
      case "fr-FR":
        motifiRefus = reservationInfo.rejectReason;
        break;
      default:
        motifiRefus = reservationInfo.rejectReasonEn;
        break;
    }
  }
  let hostName = "";
  if (reservationInfo) {
    hostName =
      reservationInfo?.residence?.host?.firstname +
      " " +
      reservationInfo?.residence?.host?.lastname;
    hostName = hostName?.replace(/\b\w/g, (l) => l.toUpperCase());
  }
  let clientName = "";
  if (reservationInfo) {
    clientName =
      reservationInfo?.user?.firstname + " " + reservationInfo?.user?.lastname;
    clientName = clientName?.replace(/\b\w/g, (l) => l.toUpperCase());
  }
  const [newCondition, setNewConditionState] = useState([]);
  const [remboursementPreview, setRemboursementPreview] = useState(0);
  const [remboursementPercent, setRemboursementPercent] = useState(0);
  const searchRemboursementPreview = async () => {
    try {
      let response = await get(
        requestEndpoint.refundPreview + "?reservationId=" + reservationInfo.id
      );
      if (response) {
        setRemboursementPercent(response.data.percent);
        setRemboursementPreview(response.data.toRefund);
      } else setRemboursementPreview(0);
    } catch (error) {
      console.log(error);
      setRemboursementPreview(0);
    }
  };
  useEffect(() => {
    if (!reservationInfo.residence) return;
    if (!withRemboursement) return;
    let residence = reservationInfo.residence;
    const tableauResultat = Object.entries(residence?.refundGrid).map(
      ([label, valeur]) => ({
        label: label.charAt(0).toLowerCase() + label.slice(1),
        valeur,
      })
    );
    searchRemboursementPreview();
    setNewConditionState(tableauResultat);
  }, [reservationInfo]);

  const [openCheckVersement, setOpenCheckVersement] = useState(false);
  const handleOpenCheckPanel = () => {
    setOpenCheckVersement(true);
  };
  const dataVersement = {
    payment_method_id: reservationInfo.versement_method_id,
    infos: reservationInfo.versementInfos,
  };
  // console.log("dataVersement", dataVersement);
  // console.log("reservationInfo", reservationInfo);
  return (
    <>
      <ContainerPersoModal
        isOpen={openCheckVersement}
        toggle={setOpenCheckVersement}
        title="Moyen de versement sélectionné"
        className="modal-edit-paiement check-modal-just"
        fullBlock={true}
        noOverflow={true}
      >
        <ModifVersement t={t} data={dataVersement} disabled={true} />
      </ContainerPersoModal>
      <div className="cancel-modal-perso modal-details-perso | flcolm">
        <button
          onClick={() => (topInfo ? handleNavigateToResidence() : {})}
          style={{ rowGap: 16 }}
          className="top-info | btn flcolm"
        >
          {topInfo && (
            <>
              <img
                src={
                  reservationInfo.residence.medias
                    ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${reservationInfo.residence.medias[0].filename}`
                    : `${process.env.REACT_APP_API_URL}/assets/home-banner.jpg`
                }
                alt="temp1"
              />
              <div className="title | flcolm addgap16">
                <label>{reservationInfo.residence.name}</label>
                <div className="location | flcolm">
                  <span>{reservationInfo.residence.address}</span>
                  <div className="star | flrow liner">
                    <Star
                      color={
                        reservationInfo.residence?.stars
                          ? "#FACC15"
                          : "var(--shade-black)"
                      }
                    />
                    <span>
                      {reservationInfo.residence?.stars
                        ? parseFloat(
                            reservationInfo.residence?.stars?.toString()
                          ).toFixed(1)
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="separator"></div>
            </>
          )}

          <div className="hote-info flrow liner maxwidth">
            {hoteMode == true ? (
              <>
                <img
                  src={
                    reservationInfo.user?.avatar
                      ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${reservationInfo.user?.avatar}`
                      : "/favicon.png"
                  }
                  alt="hote-img"
                />
                <div className="flcolm addgap8">
                  <span>Client</span>
                  <p>{clientName}</p>
                </div>
              </>
            ) : (
              <>
                <img
                  src={
                    reservationInfo.residence.host?.avatar
                      ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${reservationInfo.residence.host.avatar}`
                      : "/favicon.png"
                  }
                  alt="hote-img"
                />
                <div className="flcolm addgap8">
                  <span>{t("hoteTxt")}</span>
                  <p>{hostName}</p>
                </div>
              </>
            )}
          </div>
        </button>
        <div style={{ height: 4 }} className="separator"></div>
        <div className="info-reservation | flcolm">
          <div className="one-row | flcolm">
            <label style={{ textAlign: "left" }}>ID de la réservation</label>
            <p style={{ textAlign: "left" }}>{reservationInfo.serial_number}</p>
          </div>
          {hoteMode == true &&
            reservationInfo.versement_method_id != null &&
            reservationInfo.versementInfos != null &&
            reservationInfo.status != "cancelled" &&
            reservationInfo.status != "waiting" &&
            reservationInfo.status != "rejected" &&
            reservationInfo.status != "refused" && (
              <>
                <div className="separator"></div>
                <div className="one-row | flcolm">
                  <label style={{ textAlign: "left" }}>
                    Moyen de versement choisi
                  </label>
                  <button
                    style={{
                      position: "relative",
                      transform: "none",
                      left: 0,
                      top: 0,
                      background: "none",
                      padding: 0,
                    }}
                    onClick={handleOpenCheckPanel}
                    className={`btn flrow liner btn-select-defaut no-padding`}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}${
                        process.env.REACT_APP_LINK_PAIEMENT
                      }${
                        findVersementMethod(dataVersement?.payment_method_id)
                          ?.icon
                      }`}
                      alt="paiement-method"
                    />
                    <div className="flcolm details">
                      <h3
                        style={{
                          fontFamily: "Mori Gothic",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          findVersementMethod(dataVersement?.payment_method_id)
                            ?.label
                        }
                      </h3>
                      <p>
                        {dataVersement?.payment_method_id === 7 ||
                        findVersementMethod(dataVersement?.payment_method_id)
                          ?.label?.toString()
                          ?.toLowerCase() === "Compte bancaire".toLowerCase()
                          ? handleHideSomeNumberInRib(dataVersement?.infos?.rib)
                          : `+(${
                              dataVersement?.infos?.indicatif
                            }) ${handleSepareContact(
                              dataVersement?.infos?.contact
                            )}`}
                      </p>
                    </div>
                  </button>
                </div>
              </>
            )}
          {motifiRefus != null ? (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label style={{ textAlign: "left" }}>
                  {t("motifCanceled")}
                </label>
                <p style={{ textAlign: "left" }}>{motifiRefus}</p>
              </div>
            </>
          ) : (
            ""
          )}
          {hoteMode == false &&
          reservationInfo.refundFee != null &&
          reservationInfo.status != "payment_processing" &&
          reservationInfo.status != "cancelled" &&
          reservationInfo.status != "accepted" &&
          !withRemboursement ? (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label style={{ textAlign: "left" }}>
                  Total du Remboursement
                </label>
                <p style={{ textAlign: "left" }}>
                  {handleFormatToLocalString(
                    reservationInfo.refundFee,
                    language
                  )}{" "}
                  {showDevise}
                </p>
                {!reservationInfo.refundedAt && (
                  <p style={{ textAlign: "left" }}>
                    Votre demande de remboursement est en cours d'examen, vous
                    serez notifié une fois le remboursement accepté par
                    Trouvechap
                  </p>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {hoteMode == false &&
          reservationInfo.refundFee != null &&
          reservationInfo.status != "payment_processing" &&
          reservationInfo.refundedAt ? (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label style={{ textAlign: "left" }}>
                  Remboursement details
                </label>
                <p style={{ textAlign: "left" }}>
                  Votre demande de remboursement a été confirmé le{" "}
                  {getDate(reservationInfo.refundedAt)} par Trouvechap.
                  Toutefois vous pouvez contacter le service client pour plus de
                  détails.
                </p>
              </div>
            </>
          ) : (
            ""
          )}
          {reservationInfo.clientCode != null &&
          hoteMode == false &&
          withRemboursement == false &&
          (reservationInfo.status == "planified" ||
            reservationInfo.status == "progressing") &&
          !reservationInfo.refundAskedAt ? (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label style={{ textAlign: "left" }}>{t("monCode")}</label>
                <p style={{ textAlign: "left" }}>
                  {reservationInfo.clientCode}
                </p>
                <p style={{ textAlign: "left" }}>
                  Présentez ce code à votre hôte lors de votre arrivée.
                </p>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="separator"></div>
          <div className="one-row | flcolm">
            <label>{t("persPlaceholder")}</label>
            <p>
              {reservationInfo.adults < 10 && "0"}
              {reservationInfo.adults + " "} {t("personneTxt")}
              {reservationInfo.adults > 1 && "s"}
            </p>
          </div>
          <div className="separator"></div>
          <div className="one-row | flcolm">
            <label>{t("arrivedPlaceholderTxt")}</label>
            <p>{getDate(reservationInfo.fromDate)}</p>
          </div>
          <div className="separator"></div>
          <div className="one-row | flcolm">
            <label>{t("departPlaceholderTxt")}</label>
            <p>{getDate(reservationInfo.toDate)}</p>
          </div>
          {!hoteMode && reservationInfo.status == "payment_processing" && (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label>{"Paiement"}</label>
                <p>
                  Un lien de paiement est ouvert celui-ci durera 5 minutes et
                  est en cours de traitement, vous recevrez un email et serez
                  notifié une fois le paiement validée ou annulé.
                </p>
              </div>
            </>
          )}
          {hoteMode && !reservationInfo.refundAskedAt && (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label>{t("gainsTotauxTxt")}</label>
                <p>
                  {handleFormatToLocalString(newValueSepared, language)}
                  {" " + showDevise}
                </p>
              </div>
            </>
          )}
          {hoteMode && reservationInfo.refundAskedAt && (
            <>
              <div className="separator"></div>
              <div className="one-row | flcolm">
                <label>Information</label>
                {reservationInfo.refundedAt ? (
                  <p>
                    Trouvechap a confirmé la demande de remboursement du client
                    le {getDate(reservationInfo.refundedAt)}, vous receverai une
                    partie de la somme de la reservation selon vos conditions de
                    remboursement. vous serez notifié et contacté par le service
                    client. Pour plus d'information vous pouvez contacter le
                    service Trouvechap.
                  </p>
                ) : (
                  <p>
                    Le client a demandé un remboursement, celle ci est en cours
                    d'examen (elle respectera vos conditions de remboursement)
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        {withRemboursement && (
          <>
            <div
              style={{ padding: 10 }}
              className="info-reglement-warning flrow liner addgap8"
            >
              <div className="flex liner">
                <Warning />
              </div>
              <p>{t("infoRemboursementWarningTxt")}</p>
            </div>
            <div className="separator"></div>
            <div className="cancel-condition-residence | flcolm">
              <ul
                style={{ rowGap: 12 }}
                className="list-condition | flcolm addgap16"
              >
                {newCondition.map((condition) => (
                  <li className="flcolm liner">
                    <p style={{ maxWidth: "90%" }}>
                      {"Annulation " + condition.label}
                    </p>
                    <span style={{ fontSize: 14 }}>
                      {condition.valeur == 0
                        ? t("notRemboursement")
                        : `${t("amountToGiveTxt")}${" "}${
                            condition.valeur
                          }% ${t("totalMontantTxt")}`}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            {!reservationInfo.refundAskedAt && (
              <>
                <div className="separator"></div>
                <div className="one-row | flcolm">
                  <label>Preview du remboursement</label>
                  <p>
                    En se basant sur les conditions d'annulation de l'hôte, vous
                    percevrez {remboursementPercent}% du montant total donc{" "}
                    {handleFormatToLocalString(
                      remboursementPreview.toFixed(0),
                      language
                    )}{" "}
                    {showDevise} de la part de Trouvechap
                  </p>
                </div>
              </>
            )}
          </>
        )}
        <div
          style={{ height: 4, marginBottom: 16 }}
          className="separator"
        ></div>
        {children}
      </div>
    </>
  );
}
