import { create } from "zustand";

export const useStoreCurrencies = create((set, get) => ({
  currencies: [],
  rules: [],
  socials: [],
  handleUpdateCurrencies: (payload) => {
    set(() => ({ currencies: payload }));
  },
  handleUpdateRules: (payload) => {
    set(() => ({ rules: payload }));
  },
  handleUpdateSocials: (payload) => {
    set(() => ({ socials: payload }));
  },
}));
