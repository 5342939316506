import React, { useEffect, useState } from "react";
import useCurrentWidth from "../../services/useCurrentWidth";
import "../../styles/components/mobileReservationPanel.css";
import { useTranslation } from "react-i18next";
import {
  ChevronLeft,
  ChevronRightSimple,
  FlagIcon,
} from "../../images/icons/JsxIcons";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import SignalementModal from "./SignalementModal";
import { UserPreferences } from "../../store/UserPreferences";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { useStoreUserManager } from "../../store/UserManage";
import { useStorePageManager } from "../../store/PageManage";
import { NotificationService } from "../../services/Notification.service";
import ReservationMobilePanel from "../biggest/ReservationMobilePanel";
export default function MobileReservationPanel({ residence = null }) {
  const { windowWidth } = useCurrentWidth();
  const { t } = useTranslation();
  const [isOpen, toggle] = useState(false);
  const [toggeleReservation, setToggeleReservation] = useState(false);
  const language = UserPreferences((state) => state.language);
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const [currentPrice, setCurrentPrice] = useState(0);
  const userConnected = useStoreUserManager((state) => state.user.connected);
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleOpenReservationPanel = () => {
    handleUpdateBodyOverflow(false);
    if (!userConnected) {
      handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("needConnectTxt"));
      return;
    }
    handleUpdateDesactiveHeader(true);
    setToggeleReservation(true);
  };
  const handleCloseReservationPanel = () => {
    handleUpdateBodyOverflow(true);
    handleUpdateDesactiveHeader(false);
    setToggeleReservation(false);
  };

  useEffect(() => {
    if (!residence) return;
    let priceBase = 0;
    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = residence.price;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      // priceBase = residence.priceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      // priceBase = residence.priceDollars;
    }
    //calcul 7% de commission et ajoute au prix de base et arrondi au 5 eme, exemple 4107 => 4110 et 4103 => 4105
    priceBase = Math.round(priceBase * 1.07);
    priceBase = Math.ceil(priceBase / 10) * 10;
    setCurrentPrice(priceBase);
  }, [residence, devise, language, showDevise]);
  if (windowWidth > 550 || residence == null) return;
  return (
    <>
      <ContainerPersoModal
        className="signalement-modal-second"
        isOpen={isOpen}
        toggle={toggle}
      >
        <SignalementModal data={residence} />
      </ContainerPersoModal>

      <button onClick={() => toggle(true)} className="btn-signal | btn btw">
        <div className="left-side | flrow liner">
          <FlagIcon />
          <span>{t("signalAnnonceTxt")}</span>
        </div>
        <ChevronRightSimple />
      </button>

      <section className="mobile-reservation-panel">
        <div className="reservation-btn-bot | flrow liner btw">
          <div className="price-show | flcolm">
            <span className="price">
              {handleFormatToLocalString(currentPrice, language)} {showDevise}
            </span>
            <span className="price-note">{t("byNight")}</span>
          </div>
          <button
            onClick={handleOpenReservationPanel}
            className="reservation-btn | btn pry-btn"
          >
            {t("btnAskReservationTxt")}
          </button>
        </div>
      </section>
      {toggeleReservation && (
        <ReservationMobilePanel
          residence={residence}
          handleCloseReservationPanel={handleCloseReservationPanel}
        />
      )}
    </>
  );
}
