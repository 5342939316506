import React, { useEffect, useState } from "react";
import chevronLeft from "../../images/icons/chevron-left.svg";
import {
  checkDateJust,
  getJustDateFormat,
  handlePreviewPrice,
} from "../../services/utils";
import { UserPreferences } from "../../store/UserPreferences";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import moment from "moment";
import Tooltip from "../molecules/Tooltip";

export default function RecapReservation({
  t,
  data,
  setOpenQuitModal,
  reservationCard,
}) {
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = UserPreferences((state) => state.language);
  const [priceCalculResult, setPriceCalculResult] = useState(<></>);

  const [totalPriceCalcul, setTotalPriceCalcul] = useState(0);
  const [totalPrice, setTotalPrice] = useState(<></>);
  const [fraisPrice, setFraisPrice] = useState(0);

  const [totalPricing, setTotalPricing] = useState(0);

  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [loadingPreview, setLoadingPreview] = useState(false);
  useEffect(() => {
    let totalPrice = 0;
    let priceBase = 0;
    let daysCount = 0;
    let fraisPricing = 0;

    const date1 = moment(data.startDate).startOf("day");
    const date2 = moment(data.endDate).endOf("day");
    daysCount = date2.diff(date1, "days");
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    if (checkDateJust(data.startDate) && checkDateJust(data.endDate)) {
      setLoadingPreview(true);
      handlePreviewPrice(
        data.startDate,
        data.endDate,
        data.nbrPersonne,
        data.residenceId,
        false
      ).then((data) => {
        subTotalPriceN = data.subTotalPrice;
        fraisPriceN = data.fraisPrice;
        totalPriceResidenceN = data.totalPriceResidence;
        setSubtotalPrice(subTotalPriceN);
        setFraisPrice(fraisPriceN);
        setTotalPrice(
          <p className="info">{`${handleFormatToLocalString(
            subTotalPriceN,
            language
          )} ${showDevise}`}</p>
        );
        setTotalPricing(totalPriceResidenceN);
        // setDaySave(daysDifference);
        setPriceCalculResult(
          // <p className="info">{`Sous total ${handleFormatToLocalString(
          //   subTotalPriceN,
          //   language
          // )}${" "}${showDevise}`}</p>
          <p className="info">{`Sous total`}</p>
        );
        setReductionApply(true);
        setLoadingPreview(false);
      });
      return;
    }
    setReductionApply(false);
    if (daysCount <= 0) daysCount = 1;
    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = data.residencePrice;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      priceBase = data.residencePriceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      priceBase = data.residencePriceDollars;
    }
    priceBase = data.total ? Math.round(data.subtotal / daysCount) : priceBase;
    totalPrice = priceBase * daysCount;

    setPriceCalculResult(
      <p className="info">{`${handleFormatToLocalString(
        priceBase ?? 0,
        language
      )} ${showDevise} x ${daysCount} ${t("nightTxt")}${
        daysCount > 1 ? "s" : ""
      }`}</p>
    );
    let totalPriceWithNight = 0;
    let totalPriceResidence = 0;

    totalPriceResidence = Math.round(daysCount * priceBase);
    totalPriceWithNight = totalPriceResidence;
    fraisPricing = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPricing.toString().includes(".")) {
      fraisPricing = fraisPricing.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPricing;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }
    setFraisPrice(fraisPricing);
    setTotalPricing(totalPriceResidence);
    setTotalPriceCalcul(totalPrice);
    setTotalPrice(
      <p className="info">{`${handleFormatToLocalString(
        totalPriceWithNight,
        language
      )} ${showDevise}`}</p>
    );
  }, []);

  return (
    <div className="recap | flcolm">
      <div className="title flrow addgap16">
        <button
          onClick={() => setOpenQuitModal(true)}
          className="btn-close | btn"
        >
          <img src={chevronLeft} alt="close-btn" />
        </button>
        {reservationCard ? (
          <label>Passer au paiement</label>
        ) : (
          <label>{t("askReservationTitleTxt")}</label>
        )}
      </div>

      <label>{t("reservationDetailsTxt")}</label>
      <div className="date-one | flcolm addgap8">
        <label>Date</label>
        <span>
          {getJustDateFormat(data.startDate, "D MMMM YYYY")} -{" "}
          {getJustDateFormat(data.endDate, "D MMMM YYYY")}
        </span>
      </div>
      <div className="date-one | flcolm addgap8">
        <label>{t("personneNumber")}</label>
        <span>
          {data.nbrPersonne} {t("personneTxt")}
          {data.nbrPersonne > 1 ? "s" : ""}
        </span>
      </div>

      <div className="recap-paiement flcolm addgap24">
        <div className="price-detail | flcolm addgap8">
          <label>{t("detailsPricetxt")}</label>
          <div className="price-recap | flrow btw liner">
            {priceCalculResult}
            {totalPrice}
          </div>
        </div>
        <div className="price-frais | flcolm addgap8">
          <label>{t("fraisTxt")}</label>
          <div className="price-recap | flrow btw liner">
            <p className="info hv-tooltip">
              {`${t("feeOfService")}`}
              <Tooltip minWidth={200}>
                Les frais servent au bon fonctionnement de trouvechap et le
                support 24H/24H.
              </Tooltip>
            </p>

            <p className="info red-txt">{`${handleFormatToLocalString(
              fraisPrice.toFixed(0),
              language
            )} ${showDevise}`}</p>
          </div>
        </div>
        <div className="total-price">
          <div className="price-recap | flrow btw liner">
            <p className="big-gras">Total</p>
            <p className="total-show">{`${handleFormatToLocalString(
              totalPricing,
              language
            )} ${showDevise}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
