/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Heart, Star, XClose } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { post, remove } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  checkDateJust,
  createParameterRequestCard,
  formatStringToOneUpperCase,
} from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/cardMap.css";

function CardMap({ data, handleClose }) {
  const { t } = useTranslation();
  const [isFav, setIsFav] = useState(false);
  const [baseFav, setBaseFav] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const userData = useStoreUserManager((state) => state.user);
  const favorisUser = useStoreUserManager((state) => state.favorites);
  const [loadedImg, setLoadedImg] = useState([]);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const [hrefUrl, setHrefUrl] = useState("");
  const abortController = new AbortController();
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  const refSwiper = useRef(null);
  const cancelToken = useRef(null);

  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!data) return;
    if (!refSwiper.current) return;
    if (refSwiper.current) refSwiper.current.swiper?.update();
    if (refSwiper.current) refSwiper.current.swiper.slideTo(0, 0, false);
  }, [data]);
  const handleImageLoad = (index) => {
    setFirstLoad(true);
    let newLoadedImg = loadedImg;
    if (!newLoadedImg.includes(index)) newLoadedImg.push(index);
    setLoadedImg(newLoadedImg);
  };
  const handleCloseCard = (e) => {
    e.preventDefault();
    handleClose();
  };
  const handleFavoris = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }

    document.body.focus();
    setLoadingFav(true);
    if (userData?.connected == false) {
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("youNeedConnect1Txt"));
      return;
    }
    let favState = !isFav;
    setIsFav(favState);

    if (cancelToken.current) {
      cancelToken.current.cancel(
        "Requête annulée car une nouvelle a été déclenchée."
      );
    }
    cancelToken.current = axios.CancelToken.source();
    try {
      if (favState) {
        await post(
          requestEndpoint.favoritesAdd,
          {
            residenceId: data.id,
          },
          {},
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        NotificationService.addToQueue(0, t("addFavorisDone"));
        setIsFav(true);
        setBaseFav(true);
      } else {
        const url = `${requestEndpoint.favoritesDelete}?residenceId=` + data.id;
        await remove(url, {}, {}, { cancelToken: cancelToken.current });
        setIsFav(false);
        setBaseFav(false);
      }
      let newFav = [];
      if (favState) {
        favorisUser.map((fav) => {
          newFav.push(fav);
        });
        newFav.push(data.id);
      } else {
        favorisUser.map((fav) => {
          if (fav != data.id) newFav.push(fav);
        });
      }
      handleUpdateFavorisUser(newFav);
      setLoadingFav(false);
    } catch (error) {
      let status = error?.response?.status;
      if (status == 401)
        NotificationService.addToQueue(1, t("errorSendFavorite"));
      if (axios.isCancel(error)) return;
      setIsFav(baseFav);
    }
  };
  const handleGoToResidencepage = async () => {
    if (!data) return;
    const searchStoreParams = useStoreManager.getState().searchStoreParams;
    let startDateUrl = checkDateJust(searchStoreParams.startDate)
      ? searchStoreParams.startDate
      : "";
    let endDateUrl = checkDateJust(searchStoreParams.endDate)
      ? searchStoreParams.endDate
      : "";
    if (startDateUrl) {
      startDateUrl = new Date(startDateUrl).toISOString();
    }
    if (endDateUrl) {
      endDateUrl = new Date(endDateUrl).toISOString();
    }
    const generateUrl = createParameterRequestCard({
      startDate: startDateUrl,
      endDate: endDateUrl,
      nbrPersonne: searchStoreParams?.numPeople,
    });
    setHrefUrl(
      `/residence/${data.slug}${
        generateUrl.trim() != "" ? "?" + generateUrl : ""
      }`
    );
    return;
  };
  useEffect(() => {
    handleGoToResidencepage();
  }, [data]);
  useEffect(() => {
    if (!data) return;
    if (loadingFav) return;
    let find = false;
    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == data?.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser, loadingFav, data]);

  let address = "";
  if (data?.address) {
    address = data?.address;
  } else if (data?.location) {
    address = data?.location;
  }

  let name = formatStringToOneUpperCase(data?.name?.toLocaleLowerCase() || "");

  let infoResidence = "";
  if (data?.rooms) {
    const findChamberCount = data?.rooms.find((room) => room.roomId == 1) ?? {
      count: 0,
    };
    const findSalleBainCount = data?.rooms.find((room) => room.roomId == 5) ?? {
      count: 0,
    };
    const findSalonCount = data?.rooms.find((room) => room.roomId == 2) ?? {
      count: 0,
    };
    const chambresTxt = findChamberCount.count > 1 ? "chambres" : "chambre";
    const salleBainTxt =
      findSalleBainCount.count > 1 ? "salles de bains" : "salle de bain";
    const salonTxt = findSalonCount.count > 1 ? "salons" : "salon";
    infoResidence +=
      findChamberCount.count + " " + formatStringToOneUpperCase(chambresTxt);
    infoResidence +=
      " • " +
      findSalleBainCount.count +
      " " +
      formatStringToOneUpperCase(salleBainTxt);
    infoResidence +=
      " • " + findSalonCount.count + " " + formatStringToOneUpperCase(salonTxt);
  }
  return (
    <AnimatePresence mode="popLayout">
      {data && (
        <motion.div
          allowTransformNone={true}
          initial={{ opacity: 0, transform: "translate(-50%, 50px)" }}
          animate={{
            opacity: 1,
            transform: "translate(-50%, 0px)",
            transition: { duration: 0.3 },
          }}
          exit={{
            opacity: 0,
            transform: "translate(-50%, 50px)",
            transition: { duration: 0.15 },
          }}
          className="card-store-map"
        >
          <Link to={hrefUrl} className="link-residence">
            <Swiper
              ref={refSwiper}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              loop={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="swiper-img"
            >
              <button type="button" className="btn-fav" onClick={handleFavoris}>
                <Heart
                  color={isFav ? "var(--destructive-500)" : "transparent"}
                  stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
                />
              </button>
              {data.medias.map((media, index) => (
                <SwiperSlide>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    key={index + "-" + data.id}
                  >
                    <img
                      src={
                        data.medias
                          ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${media?.filename}`
                          : null
                      }
                      className="img-src"
                      alt="img-residence-1"
                      loading="eager"
                      fetchPriority="high"
                      onLoad={() => handleImageLoad(index + "-" + data.id)}
                      onError={() => handleImageLoad(index + "-" + data.id)}
                      // style={{
                      //   display: loadedImg.includes(index + "-" + data.id)
                      //     ? "block"
                      //     : "none",
                      // }}
                    />
                    {/* {loadedImg.includes(index + "-" + data.id) ? null : (
                      <AnimatePresence>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          style={{ width: "100%", height: "100%" }}
                          className="skeleton center-absolute"
                        ></motion.div>
                      </AnimatePresence>
                    )} */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flrow contains-info">
              <div className="card-store-map-info flex flcolm">
                <div className="top-part flex flcolm">
                  <p className="address">{address}</p>
                  <label>{name}</label>
                  <div className="flex flrow liner price-separator">
                    <span className="price">
                      {handleFormatToLocalString(
                        data.preview?.total || data.price,
                        language
                      )}{" "}
                      {showDevise} {t("byNight")}
                    </span>
                    <div className="separator"></div>
                    <div className="flex flrow liner">
                      <span className="rating">
                        {data.stars
                          ? parseFloat(data.stars?.toString()).toFixed(1)
                          : t("noneTxtNote")}
                      </span>
                      <Star
                        color={data.stars ? "#FACC15" : "var(--shade-black)"}
                      />
                    </div>
                  </div>
                </div>
                <p className="assets">{infoResidence}</p>
              </div>
              <button
                type="button"
                onClick={handleCloseCard}
                className="btn btn-cross-card"
              >
                <XClose color="#A273FF" strokeWidth={3} />
              </button>
            </div>
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CardMap;
