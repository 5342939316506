// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        btnValidate: "Validate",
        noPhoto: "No photo",
        changeTxt: "Change",
        changementPassTitle: "Change password?",
        confirmerTelOtp: "Confirm the OTP code to validate it",
        changeTelDone: "Phone number successfully changed",
        changeTelNotDone: "An error occurred while changing the phone number",
        confirmPhotoChange: "Do you confirm the change of profile photo?",
        uploadPhotoConfirm: "Profile photo successfully changed",
        confirmTxtP: "Confirm",
        errorMajPhoto: "An error occurred during the update",
        wouldQuit: "Do you want to cancel the reservation request?",
        heroTitleHome: "Romantic getaway or outing with friends?",
        heroTitleHome2: "Find the residence that suits you",
        hoteModeTxt: "Host mode",
        helpTxt: "Help",
        connectInscriTxt: "Login / Register",
        msgTxt: "Messages",
        notifTxt: "Notifications",
        favTxt: "Favorites",
        byNight: "Per Night",
        pictureName: "photo",
        profilTxt: "Profile",
        deconnexionTxt: "Logout",
        listResiTxt: "List my residence",
        listStore: "Show all our residences",
        inscriptionTitle: "Sign up!",
        inscriptionTitleMin: "Registration",
        ConnexionTitleMin: "Login",
        selectLangueLabel: "Choose the language",
        selectDeviseLabel: "Choose the currency",
        francaisName: "French",
        anglaisName: "English",
        dollarsName: "US Dollars",
        placeHolderMail: "Email address",
        issueEmailType: "Please provide a correct email address",
        placeHolderTel: "Phone",
        numberTel: "Phone number",
        noData: "No data",
        confirmPieceBeforeSend: "Confirm the sending of your document",
        habitationPlaceholderTxt: "Place of residence",
        issueTelType:
          "Please provide a correct phone number preceded by the country code +xxx",
        changeNumeroTitle: "Change number",
        changeNumero: "Change phone number",
        continueText: "Continue",
        ouText: "or",
        continueWithGoogle: "Continue with Google",
        continueWithFacebook: "Continue with Facebook",
        youHaveAnAccount: "Already have an account",
        connexionTxt: "Login",
        namePlaceholder: "Name and surname",
        issueNameType: "Please provide a correct name",
        birthdayPlaceholder: "Date of birth",
        issueBirthday: "Please provide a correct date",
        issueHabitation: "Please provide a correct place of residence",
        changeLocationDone: "Place of residence successfully changed",
        changeLocationNotDone:
          "An error occurred while changing the place of residence",
        changeLocationHabit: "Change place of residence",
        normalPasswordPlaceholder: "Password",
        issueNormalPassword: "Minimum 8 characters",
        issuePassword: "Invalid password",
        motPasseSwitchDone: "Password successfully changed",
        motPasseSwitchNotDone: "An error occurred while changing the password",
        nextProcessusConnect: "By continuing this process, you agree to our",
        conditionGene: "terms of service",
        andOur: "and our",
        policyConfidential: "privacy policy",
        policyRules: "policies and rules",
        policyRulesDescriInfoTxt: "We expect clients to adhere to these rules",
        connectTitle: "Log in",
        forgetPaaTxt: "Forgot password?",
        notHaveAccountTxt: "Don't have an account?",
        forgotPassTxt: "Forgot password",
        codeReceiveTxt: "Enter the code you received at the address",
        validityCodeTxt: "This code is valid for 5 minutes.",
        codePlaceholder: "Code",
        reSendTxt: "Resend",
        sendTxtNews: "Send",
        enterCode: "Enter a correct code",
        checkCode: "Check the code",
        addresResidenceTxt: "Residence address",
        proprietePieces: "Properties and rooms",
        newAdresseTxtInfo:
          "Enter the email address associated with your account. We will send you a link by email to reset your password.",
        sendLinkTxt: "Send the link",
        changeMdp: "Send the link",
        newPassInfoLabel: `The new password must include at least one symbol or digit, have at least 8 characters, and be different from the old one.`,
        confirmPassTxt: "Confirm password",
        issuePassTxt: "Passwords do not match",
        btnChangePass: "Change password",
        btnAll: "All",
        btnWaiting: "Waiting requests",
        reservationAcceptByHote:
          "Request accepted, we will contact you when the payment by the client is made",

        paidConfirm: "Payment confirmed",
        acceptReservation: "Accept reservation",
        btnAcceptByHote: "Accepted by host",
        cancelByClient: "Canceled by client",
        btnAskValidate: "Request validated",
        btnAccept: "Accepted",
        inWaiting: "Upcoming",
        btnCancel: "Refused",
        btnErase: "Clear all",
        btnSearch: "Search",

        onlineTxt: "Online",
        waitingTxt: "Waiting",
        echecOnlineTxt: "Failed to go online",
        disableTxt: "Disabled",

        informationResidence: "Residence information",
        btnCancelByYou: "Canceled by you",
        noDataAllReservationHote: "You have no ongoing reservations",
        noDataAllReservation: "You have not made any reservation requests",
        noDataWaitingReservation:
          "You have not made any pending reservation requests",
        noDataAcceptedReservation:
          "You have not made any accepted reservation requests",
        noDataCancelReservation:
          "You have not made any refused reservation requests",
        noDataCancelByYouReservation:
          "You have not made any canceled reservation requests",
        listPopuResiLabel:
          "List your residence on Trouvechap and open the door to rental income.",
        listPopuResiP:
          "Register your property on Trouvechap and open the door to rental income. More than",
        listPopuResiP2:
          "residences already listed with us, so what are you waiting for?",
        listMyResidenceBtn: "List my residence",
        winMonnaieWith: "Earn money with your residence",
        infoNeedConnect: "You must first have a Trouvechap account",
        infoNeedCheckerData:
          "You must complete your profile entirely before posting",
        howWorkLabel: "How Trouvechap Works?",
        howWorkP:
          "Finding and booking the residence you need is super easy with Trouvechap.",
        searchResidenceTitle: "Search for your residence",
        yourPassword: "Your password",
        newLocation: "New place of residence",
        newNumber: "New number",
        enterNumberPreceded: "Enter a number preceded by the country code +xxx",
        putGooLocation: "Provide your correct place of residence.",
        descriptionResidenceModifTxt: "Description of the residence",
        localitySwitchTxt: "Locality change",
        searchResidenceP:
          "Browse our list of over 200 furnished and easily accessible residences with very competitive prices.",
        comparePriceTitle: "Compare the offers",
        searchInLoading: "Search in progress",
        comparePriceP:
          "Refine your search criteria according to what you want in your residences and compare the different offers in our list.",
        reserveResidenceTitle: "Book the one of your choice",
        reserveResidenceP:
          "Satisfied? Set a date and book the residence you have chosen simply and quickly.",
        shareBtn: "Share",
        saveBtn: "Save",
        saveAndQuitBtn: "Save and exit",
        wouldSaveAndQuit:
          "Do you want to save the progress of your ad and exit?",
        resiSaved: "Ad saved",
        handleQuitWithoutSave: "Exit without saving",
        cancelConfirm: "Yes, cancel",
        nopTxtAvertissement: "No penalty will be applied",
        askReservationTitleTxt: "Reservation request",
        reservationDetailsTxt: "Reservation details",
        monCode: "My code",
        personneNumber: "Number of people",
        personneTxt: "Person",
        buyWithLbl: "Pay with",
        infoLocationLater:
          "The exact address of the residence will be revealed to you after booking",
        reglementInfoTxt:
          "Payment for your stay will only be made when the host approves your reservation request.",
        cancelConditionTxt: "Cancellation conditions",
        cancelConditionModifDescri:
          "Define the refund rate that applies in case of reservation cancellation",
        annulationBtwStep1: "Cancellation between 48h and 1 week",
        annulationBtwStep2: "Cancellation between 1 week and 1 month",
        annulationBtwStep3: "Cancellation between 1 month and 3 months",
        notRemboursement: "No refund",
        totalMontantTxt: "of the total amount",
        btnAskReservationTxt: "Request a reservation",
        infoReglementTxt1:
          "By clicking the button below, I agree to the following conditions:",
        infoReglementTxt2: "Host's house rules",
        infoReglementTxt3: "Trouvechap's Privacy Policy",
        infoReglementTxt4: "and I give my consent for Trouvechap",
        infoReglementTxt5: "to debit my payment method",
        infoReglementTxt6: "if I am responsible for damages.",
        detailsPricetxt: "Price details",
        priceBeforeFeeTxt: "Price before fees",
        nightTxt: "Night",
        fraisTxt: "Fees",
        departPlaceholderTxt: "Departure",
        arrivedPlaceholderTxt: "Arrival",
        cancelReservationTxt: "Cancel reservation",
        nbDate: "Note: gray dates are unavailable",
        chooseDateTxt: "Choose your date.",
        optionnelTxt: "optional",
        motifCanceled: "Reason for cancellation",
        iChangedMyMindTxt: "I changed my mind",
        aucunMotifDonneTxt: "No reason given",
        aucunMotifDonne2Txt: "No specific reason",
        ichooseOtherResiTxt: "I chose another residence",
        myReservationsTxt: "My reservations",
        confirmCancelReservationTxt:
          "The reservation has been successfully canceled",
        confirmCancelReservation:
          "Do you confirm the cancellation of the reservation?",
        thisActionNotReversible: "This action is not reversible!",
        reservationAskTitle: "Request a reservation",
        infoReservationTxt: "You will not be debited now.",
        infoReservationHoteTxt:
          "You will only be debited when the host approves the reservation request.",
        signalAnnonceTxt: "Report this ad",
        persPlaceholder: "Number of people",
        showMoreTxt: "Show more",

        titleProposLogement: "About the accommodation",
        facturationTxt: "Billing address",

        galeriePhotoTxt: "Photo gallery",
        shareTxtGalerie: "Share",
        saveTxtGalerie: "Save",
        hoteTxt: "host",
        chambreTxt: "room",
        bedTxt: "bed",
        salleBainTxt: "bathroom",
        cuisineTxt: "kitchen",
        piecesNumber: "Number of rooms",
        commoditiesTxt: "Amenities",
        typeResidenceTxt: "Type of residence",
        homeTxt: "House",
        appartTxt: "Apartment",
        challetTxt: "Chalet",
        labelComodities: "Amenities",
        chambersAndSpaces: "Rooms and spaces",
        labelShowAllCommodities: "View all amenities",
        labelApercus: "Preview",
        specialInstructionsLabel: "Special instructions",
        noSpecialInstruction: "No special instructions",

        reglementInterieurTxt: "House rules",
        nothingToShow: "Nothing to show",

        typeSocialActivitieTxt: "Type of social activity",

        othersTxt: "Others",
        commentaryTxt: "comment",
        showAllCommentaryTxt: "Show comments",

        logementTxt: "Accommodation location",
        proprieteTxt: "Cleanliness",
        EmplacementTxt: "Location",
        RapportQualitePrixTxt: "Quality/price ratio",
        leaveCommentaryTxt: "Leave a comment",
        redigerCommentaryTxt: "Write a comment",
        nextBtnTxt: "Next",
        writeCommentaryTxt: "Write your comment",
        propeteDescriptionTxt: "Was the accommodation clean?",
        emplacementDescriptionTxt:
          "Is the accommodation easily accessible and close to shops?",
        rapportQualitePrixDescriptionTxt:
          "Is the cleanliness, location, and comfort offered by this accommodation worth the price?",
        closePanelTxt: "Close the panel",
        sayMoreTxt: "Tell us more (optional)",
        sendTxt: "Publish",
        writeCommentaryPlaceholderTxt: "Enter your comment here",
        writeEmailPlaceholderTxt: "Enter your email address",

        firstNamePlaceholderTxt: "First name",
        lastNamePlaceholderTxt: "Last name",

        errorRegistrationTxt: "An error occurred during registration",
        tryAgainTxt: "Try again later",

        userAlreadyExistTxt: "The user already exists",
        inscriptionDoneTxt: "Registration successful",

        connexionDoneTxt: "Login successful",
        errorConnexionTxt: "Incorrect password or email",

        codeSendTxt: "A password reset code has been sent to your email",
        userNoExistTxt: "The user does not exist",
        codeNoValidTxt: "The code is not valid",
        passChanged: "Password changed successfully",

        proposeLogementTxt: "What this accommodation offers",

        residenceMoreVisitedTxt: "Our most visited residences",

        morePaiementMethodTxt: "Multiple payment methods",
        morePaiementMethodP: "Book and pay securely with the",
        morePaiementMethodP2: "service that suits you",

        paiementMethodCardTxtP1: "Book and pay securely with",
        paiementMethodCardTxtP2: "the service that suits you",

        mobileAppTxtTilte1: "It's still",
        mobileAppTxtTilte2: "easy and quick",
        mobileAppTxtTilte3: "with the mobile app.",

        mobileParagrapheTxt:
          "With our mobile app, find the residence of your dreams and book it in moments, wherever you are. No more long waits and uncertainties.",

        commentaryInfoTitle1: "Hundreds of satisfied customers",
        commentaryInfoP: "Discover what our wonderful clientele thinks of us",
        seeAllCommentary: "See all comments",

        faqTxt: "FAQ",
        aProprosTxt: "About",
        termesConditionsTxt: "Terms and conditions",
        partenairesTxt: "Partners",
        listResiTxt2: "on Trouvechap",

        recieveLastNewsTxt:
          "Receive the latest information about new services and products from Trouvechap.",

        animalAllowedTxt: "Pets allowed",
        smokeAllowedTxt: "Smoking allowed",
        eventAllowedTxt: "Event allowed",

        allRightReservedTxt: "All rights reserved",
        needConnectTxt: "You must log in to continue",
        demandeReservationTxt: "Request a reservation",
        whenYouWantTravelTxt: "When do you want to travel?",
        titleConfirmReservationTxt: "Confirm the reservation",
        montantToBePaidTxt: "Amount to be paid",
        recapTxt: "Summary",
        jourTxt: "Day",
        // nightTxt: "Night",
        needReservationParamsTxt: "You must first select a residence",
        OneWeekTxt: "1 week",
        addTravelerTxt: "Add traveler",
        searchDestination: "Search for a destination",
        placeholderDestinationTxt: "Where do you want to go?",
        lastSearchTxt: "Last searches",

        destinationInputError: "Please enter a destination",
        addDateInputError: "Please enter an arrival and departure date",
        addPersonneInputError: "Please enter the number of people",
        fourchetPriceTxt: "Price range",
        filterTxt: "Filters",
        youNeedConnect1Txt: "You must be logged in",
        youNeedConnect: "You must be logged in to access this page.",
        loadFavorisTxt: "Loading your favorites...",
        loadNotificationTxt: "Loading your notifications...",
        createFirstFavorisTxt: "Create your first list of favorites",
        favorisTxt: "Favorites",
        addFavorisDone: "Residence added to your favorites",
        seconPFavorisTxt: `During your searches, click on the heart to save
        homes and experiences that interest you in your
        favorites.`,
        // loadNotificationTxt: "Loading your notifications...",
        noNotificationTxt: "You have no notifications",

        errorSendNewsLetter:
          "This email address is already subscribed to our newsletter",
        addNewsLetterDone: "You have successfully subscribed to our newsletter",
        mesResiMenuTxt: "My residences",
        calendarMenuTxt: "Calendar",
        reservationMenuTxt: "Reservations",
        statistiquesMenuTxt: "Statistics",

        tailleMaxFile: "The file",
        // tailleMaxFile: "exceeds the maximum allowed (2.5MB)",
        uniquementFile: "Only files of type png, jpeg, and pdf are accepted",
        pieceUploadError: "An error occurred while uploading the piece",
        pieceUploadDone: "Piece uploaded successfully",
        deposerPieceVerificationTxt: "Submit your files for verification",
        pieceAcceptsTxt: "We only accept PDF and PNG",
        completeProfileTxt1: "Complete your profile",
        addPiceIdentityTxt: "Add your identity card",
        piceInCheckTxt: "Piece under verification",
        piceInCheck2Txt: "Piece under validation",
        piceInCheck23xt: "Cannot be modified during this phase",
        pieceValideTxt:
          "Your piece has been validated, post your residences now",
        pieceValidTxt2: "Piece validated",
        pieceValidTxt3: "Cannot be modified / contact support",
        confirmSendPieceTxt: "Do you confirm the submission of your piece?",

        informationPersonnelleTxt: "Personal information",

        tableResidences: "Residences",
        tableStatut: "Status",
        tableChambres: "Rooms",
        tableBathroom: "Bathroom",
        tableBed: "Beds",
        tablePersons: "People",
        tableLocalisation: "Location",
        tableLastModification: "Last modification",
        modifierTxt: "Edit",
        todayTxt: "Today",
        yesterdayTxt: "Yesterday",
        apercuResidenceTxt: "Residence overview",
        seeCalendarTxt: "View calendar",
        deleteBtn: "Delete",
        disableBtn: "Disable",
        addResidence: "Add a residence",

        checkedTxt: "Verifications done",

        pieceVerifierTxt: "Identity card",
        searchRidencePlaceholder: "Search for a residence",
        activationAccount: "Activation of your Trouvechap email address",
        checkEmailLabel: "Verification of your email address",
        errorEmailVerif:
          "The token used is invalid, please request another one from the profile menu",
        accountAlreadyVerified: "Your account is already verified",
        accountAlreadyVerified2:
          "Your email address has been successfully verified, see you later on Trouvechap",
        accountVerified3Txt: "Your email address is verified",

        noReservationTxt: "You have no reservations",

        waitingHotelTxt: "Waiting for the host",
        waitingPaiementTxt: "Waiting for payment",
        paiementAcceptTxt: "Payment accepted",
        paiementCancelTxt: "Canceled",
        paiementCancelByHote: "Refused by the host",
        amountToBePaidTxt: "Amount to be paid",
        seeDetailsTxt: "See details",

        passToPaidTxt: "Proceed to payment",
        cancelTxtPaid: "Cancel",

        // cancelReservationTxt: "Cancel reservation",
        page404infoTxt:
          "The page or residence you are looking for does not exist!",
        page404btnTxt: "Back to home",
        endOfSearchTxt: "End of results!",
        infoTxtResultatNone: "No residence matches your criteria!",
        infoTxtResultatNone2: `Feel free to try other combinations of filters to
        find the best one for you.`,
        namePrenomPlaceholderTxt: "Name and surname",
        namePrenomErrorTxt: "Please enter a correct name and surname",
        emailPlaceholderTxt: "Email address",
        emailPlaceholderErrorTxt: "Please enter a correct email address",
        numeroTelephonePlaceholderTxt: "Phone number",
        numeroTelephonePlaceholderErrorTxt:
          "Please enter a correct phone number preceded by the country code +xxx",
        adresseGeoPlaceholderTxt: "Geographical address",
        adresseGeoPlaceholderErrorTxt:
          "Please enter a correct geographical address",
        villePlaceholderTxt: "City",
        villePlaceholderErrorTxt: "Please enter a correct city",
        codeZipPlaceholderTxt: "Zip code",
        codeZipPlaceholderErrorTxt: "Please enter a correct zip code",
        provincePlaceholderTxt: "Province",
        provincePlaceholderErrorTxt: "Please enter a correct province",
        paysCountryPlaceholderTxt: "Country",
        paysProvincePlaceholderTxt: "Province",
        paysCountryPlaceholderErrorTxt: "Please enter a correct country",

        contactAssistanceWithWhaTitleTxt: "Contact support with WhatsApp",
        paragraphWhaTxt: "You can contact us via WhatsApp for any assistance",
        btnWhaTxt: "Contact",
        feeOfService: "Service Fee",
        confirmReservationTxt: "Confirm reservation",
        confirmInfoTxt:
          "You will not be charged now. The host must accept your reservation request before you have to pay.",
        confirmSaveBillingTxt:
          "You can save your billing information for future reservations",
        confirmPaiementTxt: "Confirm",
        saveBillingTxt: "Save information",
        setSaveBillingTxt: "Save information",
        saveAndReserveBillingTxt: "Save and reserve",
        justReserveTxt: "Just reserve",
        reservationDoneTxt: "Reservation successfully made",
        posResidenceTxt: "Position of my residence",
        //step 1
        step1Lbl: "STEP 1",
        step1Tilte: "Tell us about your accommodation",
        step1SubTitle: `During this step, we will ask you for information
        about the accommodation you offer and whether guests
        can reserve it in its entirety or if you only rent
        a room. We will then ask you to indicate its
        location and capacity.`,
        //step 7
        step7Title: "What amenities does your accommodation offer?",
        step7SubTitle: `You can add amenities once your listing is published.`,
        //step 8
        step15Title: "Write your house rules",
        step15SubTitle: `Travelers are required to respect your house rules and
        may be excluded from Airbnb if they cause problems.`,
        //step 9
        titleLabel1: "Allowed events",
        paragraphL1: `Your residence is available for specific social activities
        such as parties or events.`,
        titleLabel2: "Smoking allowed",
        titleLabel3: "Smoking allowed",

        retourTxtAdd: "Back",
        continuerTxtAdd: "Continue",
        letsGoTxtAdd: "Let's go!",
        finishAddTxt: "Finish",
        nextTxtAdd: "Next",
        perfectAdd: "Perfect",
        confirmSendResidenceTxt:
          "Do you confirm the submission of your residence?",
        paragraphConfirmSendResidenceTxt:
          "You can edit your residence once it is published, but you can no longer change its location.",
        tcLearnBefore:
          "(Trouvechap will review your residence before publishing it)",
        resiAdded: "Residence successfully added",

        //step17
        step18Title: "Congratulations",
        step18SubTitle: `Your residence has been submitted for verification by the Trouvechap team and will soon be added to our catalog. You will now switch to Host mode to adjust your reservation settings.`,
        continueToHostMode: "Continue to Host mode",

        reservedTxt: "Reserved",

        residenceOneTxt: "Residence",
        allResidencesTxt: "All residences",
        selectAnnonceTxt: "Select a listing",
        basePriceTxt: "Base price",
        weekendPriceTxt: "Custom weekend price",
        ajouterBtnTxt: "Add",
        priceResidenceLabelTxt: "Residence price",
        priceResidenceWeekendTxt: "Friday night - Sunday night",
        fontionNotAvailableTxt:
          "Feature not available at the moment, you will receive a notification when it becomes available",
        reductionTxt: "Discounts",
        reductionTxt0: "Discount",
        reductionLenghtTxt: "Activate discounts to attract more customers",
        reductionLenghtTxtSeven: "For 7 nights",
        reductionLenghtTxtMonth: "For 28 nights",
        errorSaveReductionTxt: "An error occurred while saving the discounts",
        reductionNotAvailableTxt:
          "Discounts not available, you will receive a notification when it becomes available on Trouvechap, options will not be saved",
        noResidenceTxt: "You have no residence",
        confirmationAcceptReservationTxt: "Confirm reservation",
        noResidenceTxt0: "Add a residence from the list my residence menu",
        reservationMenuTxtLabel: "Reservations",
        lastUpdateTxt: "Last update: Today",
        // confirmReservationTxt: "Confirm a visit",

        sorryCantAcceptNow:
          "Sorry, I cannot accept a reservation at the moment",
        residenceEnRepairTxt: "Residence under repair",
        residenceAlreadyBookedTxt:
          "My residence is already booked for these dates",
        customCancelBookingTxt: "Custom cancellation of this booking",
        writeCancelBtnTxt: "Write the reason for refusal",
        cancelledReservationTxt: "Refuse reservation",
        motifDeRefusTxt: "Reason for refusal",
        gainsTotauxTxt: "Total earnings",

        clientConfirmerTxt:
          "Client confirmed, Thank you for helping us protect you",
        refuseBtnTxtCancel: "Cancel",
        refuseBtnTxt: "Refuse",
        acceptBtnTxt: "Accept",
        confirmVenueTxt:
          "Confirm the client's visit to your residence, this will help trace your residence and protect you in case of a dispute",
        repondreTxtBtn: "Answer",
        repondreCommentaireTxt: "Reply to the comment",
        clientSendMailDoneTxt: "Mail sent successfully",
        dataTestCantAdd: "You cannot add data in test mode",
        repondrecommentaireParagraph:
          "Your response will be sent by email to the client, so please be polite and professional",
        performancesTitle: "Performances",
        noteGlobaleTxt: "Overall ratings",
        gainsTxt: "Earnings",
        avisClientTxt: "Customer reviews",
        informationsTxt: "Information",
        percentStars5Txt: "Percentage of 5 stars",
        cumulNoteTxt: "Cumulative overall rating",
        // gainsTxt: "Earnings",
        globalChartParagraph:
          "Your average overall quality rating will appear here as soon as a guest leaves a review. ",
        chartNotAvailableTxt:
          "Chart not available, come back later, we will send you a notification when it becomes available",
        selectPeriodeTxt: "Select a period",
        selectResiTxt: "Choose a residence",
        beneficeForTxt: "recorded profits for",
        detailsTxtGains: "Details",
        allResidenceTxt: "All residences",
        // avisClientTxt: "Customer reviews",
        noAvisClientTitle: "Customer reviews will be displayed here",
        noAvisClientP:
          "We will inform you as soon as a customer leaves comments",
        generaleInfoTxt: "General information",
        consultationLastTxt: "Consultations in the last 30 days",
        newReservationTxt: "New reservations, last 30 days",
        tauxConversionLast: "Conversion rate, last 30 days",
        dureeMoyenneSejour: "Average length of stay",
        trafficTxt: "Traffic",
        annulationReservationsTxt: "Cancellation of reservations",
        consultationsTxtSelect: "Consultations",
        reservationsTxtSelect: "Reservations",
        conversionTxtSelect: "Conversions",
        durreSejourTxtSelect: "Length of stay",
        residenceTxtTable: "Residence",
        tauxAnnulationTxtTable: "Cancellation rate",
        tauxReservationTxtTable: "Reservation rate",
        showTableInfoTxt: "Displaying 1 to 10",

        noneTxtNote: "N/A",
        errorSendFavorite: "An error occurred while adding to favorites",
        waitiwaitingTxtngHotelTxt: "Waiting for the host",
        partialTxt: "Partial",
        fullResidenceTxt: "Full residence",
        amountToGiveTxt: "Amount to be repaid : ",
        commentaryNotAllowTxt: "You cannot leave a comment, before staying",
        contactInfoTitle1: "Contact...",
        contactInfoP: "Contact the host....",
      },
    },
    fr: {
      translation: {
        btnValidate: "Valider",
        noPhoto: "Aucune photo",
        changeTxt: "Changer",
        changementPassTitle: "Changer de mot de passe ?",
        confirmerTelOtp: "Confirmer le code OTP code afin de le valider",
        changeTelDone: "Numéro de téléphone modifié avec succès",
        changeTelNotDone:
          "Une erreur est survenue lors de la modification du numéro de téléphone",
        confirmPhotoChange: "Confirmez-vous le changement de photo de profil ?",
        uploadPhotoConfirm: "Photo de profil modifiée avec succès",
        confirmTxtP: "Confirmer",
        errorMajPhoto: "Une erreur est survenue lors de la mise à jour",
        wouldQuit: "Voulez-vous annuler la demande de reservation?",
        heroTitleHome: "Escapade en amoureux ou sortie entre amis?",
        heroTitleHome2: "Trouvez la résidence qu’il vous faut",
        hoteModeTxt: "Mode hôte",
        helpTxt: "Aide",
        connectInscriTxt: "Connexion / Inscription",
        msgTxt: "Messages",
        notifTxt: "Notifications",
        favTxt: "Favoris",
        byNight: "Par Nuit",
        pictureName: "photo",
        profilTxt: "Profil",
        deconnexionTxt: "Déconnexion",
        listResiTxt: "Lister ma residence",
        listStore: "Voir toutes nos résidences",
        inscriptionTitle: "Inscrivez-vous!",
        inscriptionTitleMin: "Inscription",
        ConnexionTitleMin: "Connexion",
        selectLangueLabel: "Choisir la langue",
        selectDeviseLabel: "Choisir la devise",
        francaisName: "Français",
        anglaisName: "Anglais",
        dollarsName: "Dollars Americain",
        placeHolderMail: "Adresse email",
        placeHolderObjectMail: "Objet du mail",
        issueEmailType: "Veillez renseigner une addresse email correct",
        placeHolderTel: `Téléphone`,
        numberTel: `Numéro de téléphone`,
        noData: `Aucune donnée`,
        confirmPieceBeforeSend: `Confirmer l'envoi de votre pièce`,
        habitationPlaceholderTxt: `Lieu d’habitation`,
        issueTelType: `Veillez renseigner un numéro de téléphone correct précédé de
        l'indicatif +xxx`,
        changeNumeroTitle: `Changer de numero`,
        changeNumero: `Changer le numero de téphone`,
        continueText: "Continuer",
        ouText: "ou",
        continueWithGoogle: "Continuer avec Google",
        continueWithFacebook: "Continuer avec Facebook",
        youHaveAnAccount: "Vous avez déjà un compte",
        connexionTxt: "connexion",
        namePlaceholder: "Nom et prénoms",
        issueNameType: "Veillez renseigner un nom correct",
        birthdayPlaceholder: "Date de naissance",
        issueBirthday: "Veillez renseigner un date correct",
        issueHabitation: "Veillez un lieu d'habitation correct",
        changeLocationDone: "Lieu d'habitation modifié avec succès",
        changeLocationNotDone:
          "Une erreur est survenue lors de la modification du lieu d'habitation",
        changeLocationHabit: "Changer le lieu d'habitation",
        normalPasswordPlaceholder: "Mot de passe",
        issueNormalPassword:
          "8 caractères minimun, une majuscule et un chiffre au moins",
        issuePassword: "Mot de passe non valide",
        motPasseSwitchDone: "Mot de passe modifié avec succès",

        motPasseSwitchNotDone:
          "Une erreur est survenue lors de la modification du mot de passe",
        nextProcessusConnect: "En poursuivant ce processus, vous acceptez nos",
        conditionGene: "conditions générales d’utilisation",
        andOur: "et notre",
        policyConfidential: "politique de confidentialité",
        policyRules: "Politiques et règles",
        policyRulesDescriInfoTxt:
          "On attends des clients qu’ils respectent ces règles",
        connectTitle: "Connectez-vous",
        forgetPaaTxt: "Mot de passe oublié ?",
        notHaveAccountTxt: "Vous n'avez pas de compte ?",
        forgotPassTxt: "Mot de passe oublié",
        codeReceiveTxt: "Saisissez le code que vous avez réçu à l'adresse",
        validityCodeTxt: " celui-ci est valide pendant 5 minutes.",
        codePlaceholder: "Code",
        reSendTxt: "Renvoyer",
        sendTxtNews: "Envoyer",
        enterCode: "Entrez un code correct",
        checkCode: "Verifier le code",
        addresResidenceTxt: "Adresse de la résidence",
        proprietePieces: "Propriétés et pièces",
        newAdresseTxtInfo: `Saisissez l'adresse e-mail associée à votre compte. Nous vous
        enverrons un lien par e-mail pour réinitialiser votre mot de passe.`,
        sendLinkTxt: " Envoyer le lien",

        changeMdp: " Envoyer le lien",
        newPassInfoLabel: `Le nouveau mot de passe doit contenir au moins un symbole ou un
        chiffre et comporter au moins 8 caractères et être différent de
        l’ancien.`,
        confirmPassTxt: "Confirmer le mot de passe",
        issuePassTxt: "les mot de passe sont différents",
        btnChangePass: "Changer le mot de passe",
        btnAll: "Tout",
        btnWaiting: "Demande en attente",
        reservationAcceptByHote:
          "Demande acceptée, nous vous contacterons lorsque la réservation sera confirmée par le client",

        paidConfirm: "Paiement confirmé",
        acceptReservation: "Accepter la réservation",
        btnAcceptByHote: "Acceptée par l'hôte",
        cancelByClient: "Annulée par le client",
        btnAskValidate: "Demande validée",
        btnAccept: "Accepté",
        inWaiting: "À venir",
        btnCancel: "Refusée",
        btnErase: "Tout effacer",
        btnSearch: "Chercher",

        onlineTxt: "En ligne",
        waitingTxt: "En attente",
        echecOnlineTxt: "Echec de la mise en ligne",
        disableTxt: "Désactivé",

        informationResidence: "Information de la résidence",
        btnCancelByYou: "Annulée par vous",
        noDataAllReservationHote: "Vous n’avez aucune réservation en cours",
        noDataAllReservation:
          "Vous n’avez effectué aucune demande de réservation",
        noDataWaitingReservation:
          "Vous n’avez effectué aucune demande de réservation en attente",
        noDataAcceptedReservation:
          "Vous n’avez effectué aucune demande de réservation acceptée",
        noDataComingReservation:
          "Vous n’avez effectué aucune demande de réservation à venir",
        noDataCancelReservation:
          "Vous n’avez effectué aucune demande de réservation réfusée",
        noDataCancelByYouReservation:
          "Vous n’avez effectué aucune demande de réservation annulée",
        listPopuResiLabel: `Listez votre résidence sur Trouvechap et ouvrez la porte à des
        revenus locatifs.`,
        // listPopuResiP: `Inscrivez votre propriété sur Trouvechap et ouvrez la porte à des
        // revenus locatifs. Plus de`,
        listPopuResiP: `Plus de`,
        listPopuResiP2: `résidences déjà listées chez nous, alors qu’attendez-vous?`,
        listMyResidenceBtn: "Lister ma résidence",
        winMonnaieWith: "Gagnez de l'argent avec votre rési",
        infoNeedConnect: "Vous devez d'abord posseder un compte Trouvechap",
        infoNeedCheckerData:
          "Vous devez completer votre profil entièrement avant de poster",
        howWorkLabel: "Comment fonctionne trouvechap ?",
        howWorkP: `Trouvez et réservez la résidence dont vous avez besoin avec Trouvechap, c'est super facile !`,
        searchResidenceTitle: `Cherche ta résidence`,
        yourPassword: "Votre mot de passe",
        newLocation: "Nouveau lieu d'habitation",
        newNumber: "Nouveau numéro",
        enterNumberPreceded: "Entrez un numéro précédé de l'indicatif +xxx",
        putGooLocation: "Renseignez votre lieu d'habitation correct.",
        descriptionResidenceModifTxt: `Description de la résidence`,
        localitySwitchTxt: "Changement de localité",
        searchResidenceP: `Parcourez notre liste de plus de 1000 résidences meublées et faciles d'accès et avec des prix très compétitifs.`,
        comparePriceTitle: "Compare les offres",
        searchInLoading: "Recherche en cours",
        comparePriceP: `Affinez vos critères de recherche selon ce que vous désirez avoir dans votre résidence et comparez les différentes offres de notre liste.`,
        reserveResidenceTitle: "Réserve celle de ton choix",
        reserveResidenceP: `Satisfait ? Définissez une date et réservez la rési que vous avez choisi simplement et rapidement.`,
        shareBtn: "Partager",
        saveBtn: "Enregistrer",
        saveAndQuitBtn: "Enregistrer et quitter",
        wouldSaveAndQuit:
          "Voulez pouvez enregistrer la progression de votre annonce et quitter ?",
        resiSaved: "Annonce enregistrée",
        handleQuitWithoutSave: "Quitter sans enregistrer",
        cancelConfirm: "Oui annuler",
        nopTxtAvertissement: "Aucune penalités ne sera appliquée",
        askReservationTitleTxt: "Demande de réservation",
        reservationDetailsTxt: "Détails de réservation",
        monCode: "Mon code",
        personneNumber: "Nombre de personnes",
        personneTxt: "Personne",
        buyWithLbl: "Payez avec",
        infoLocationLater:
          "L’adresse exacte de la résidence vous sera dévoilée après réservation",
        reglementInfoTxt:
          "Le règlement de votre séjour se fera uniquement lorsque l’hôte aura approuvée votre demande de réservation.",
        cancelConditionTxt: "Conditions d’annulation",
        cancelConditionModifDescri:
          "Définissez le taux de remboursement qui s’applique en cas d’annulation de réservation",
        annulationBtwStep1: "Annulation entre 48h et 1 semaine",
        annulationBtwStep2: "Annulation entre 1 semaine et 1 mois",
        annulationBtwStep3: "Annulation entre 1 mois et 3 mois",
        notRemboursement: "Pas de remboursement",
        totalMontantTxt: "du montant total",
        btnAskReservationTxt: "Demander une réservation",
        infoReglementTxt1: `En cliquant sur le bouton ci-dessous, j'accepte les conditions suivantes :`,
        infoReglementTxt2: `Règlement intérieur de l'hôte`,
        infoReglementTxt3: `Politique de confidentialité de Trouvechap`,
        infoReglementTxt4: `et je donne mon accord pour que Trouvechap`,
        infoReglementTxt5: `débite mon mode de paiement`,
        infoReglementTxt6: ` si je suis responsable de dommages.`,
        detailsPricetxt: "Détails du prix",
        priceBeforeFeeTxt: "Prix avant frais",
        nightTxt: "Nuit",
        fraisTxt: "Frais",
        departPlaceholderTxt: "Départ",
        arrivedPlaceholderTxt: "Arrivée",
        cancelReservationTxt: "Annuler la reservation",
        nbDate: "Nb: les dates en gris sont indisponibles",
        chooseDateTxt: "Choisissez votre date.",

        optionnelTxt: "optionnel",
        motifCanceled: "Motif de l'annulation",
        iChangedMyMindTxt: "J'ai changé d'avis",
        aucunMotifDonneTxt: "Aucun motif fourni",
        aucunMotifDonne2Txt: "Aucun motif particulier",
        ichooseOtherResiTxt: "J'ai choisi une autre résidence",
        myReservationsTxt: "Mes réservations",
        confirmCancelReservationTxt: "La reservation a bien été annulé",
        confirmCancelReservation:
          "Confirmez-vous l'annulation de la réservation ?",
        thisActionNotReversible: "Cette action n'est pas reversible !",

        reservationAskTitle: "Demander une réservation",
        infoReservationTxt: "Vous ne serez pas débité maintenant.",
        infoReservationHoteTxt:
          "Vous serez débités uniquement lorsque l’hôte aura accepté la demande de réservation.",
        signalAnnonceTxt: "Signaler cette annonce",
        persPlaceholder: "Nombre de personnes",

        showMoreTxt: "Voir plus",

        titleProposLogement: "A propos du logement",
        facturationTxt: "Adresse de facturation",

        galeriePhotoTxt: "Galerie photo",
        shareTxtGalerie: "Partager",
        saveTxtGalerie: "Enregistrer",
        hoteTxt: "hôte",
        chambreTxt: "chambre",
        bedTxt: "lit",
        salleBainTxt: "Salles de bain",
        cuisineTxt: "cuisine",
        piecesNumber: "Nombre de pièces",
        commoditiesTxt: "Commodités",
        typeResidenceTxt: "Type de résidence",
        homeTxt: "Maison",
        appartTxt: "Appartement",
        challetTxt: "Chalet",
        labelComodities: "Commodités",
        chambersAndSpaces: "Chambres et espaces",
        labelShowAllCommodities: "Voir toutes les commodités",
        labelApercus: "Aperçu",
        specialInstructionsLabel: "Instructions spéciales",
        noSpecialInstruction: "Aucune instruction spéciale",

        reglementInterieurTxt: "Règlement intérieur",
        nothingToShow: "Rien a afficher",

        typeSocialActivitieTxt: "Type d'activités sociales",

        othersTxt: "Autres",
        commentaryTxt: "commentaire",
        showAllCommentaryTxt: "Afficher les commentaires",

        logementTxt: "Localisation du logement",
        proprieteTxt: "Propreté",
        EmplacementTxt: "Emplacement",
        RapportQualitePrixTxt: "Rapport qualité-prix",
        leaveCommentaryTxt: "Laisser un commentaire",
        redigerCommentaryTxt: "Rédiger un commentaire",
        nextBtnTxt: "Suivant",
        writeCommentaryTxt: "Ecrivez votre commentaire",
        propeteDescriptionTxt: "Le logement était-il propre ?",
        emplacementDescriptionTxt:
          "Est-ce que le logement est facilement accessible et proche des commerces ?",
        rapportQualitePrixDescriptionTxt:
          "La propreté, l’emplacement, ainsi que le confort offerts par ce logement sont-ils à la hauteur du prix ?",
        closePanelTxt: "Fermer le panel",
        sayMoreTxt: "Dites-nous en plus (optionnel)",
        sendTxt: "Publier",
        writeCommentaryPlaceholderTxt: "Entrez votre commentaire ici",
        writeEmailPlaceholderTxt: "Entrez votre adresse email",

        firstNamePlaceholderTxt: "Prénom",
        lastNamePlaceholderTxt: "Nom",

        errorRegistrationTxt: "Une erreur est survenue lors de l'inscription",
        tryAgainTxt: "Reessayez plus tard",

        userAlreadyExistTxt: "L'utilisateur existe déjà",
        inscriptionDoneTxt: "Inscription effectuée avec succès",

        connexionDoneTxt: "Connexion effectuée avec succès",
        errorConnexionTxt: "Mot de passe ou email incorrect",

        codeSendTxt:
          "Un code de renitialisation de mot de passe vous a été envoyé par mail",
        userNoExistTxt: "L'utilisateur n'existe pas",
        codeNoValidTxt: "Le code n'est pas valide",
        passChanged: "Mot de passe changé avec succès",

        proposeLogementTxt: "Ce que propose ce logement",

        residenceMoreVisitedTxt: "Nos résidences les plus visitées",

        morePaiementMethodTxt: "Plusieurs moyens de paiement",
        morePaiementMethodP: `Réservez et payez en toute sécurité avec le service`,
        morePaiementMethodP2: `qui vous convient`,

        paiementMethodCardTxtP1: "Réservez et payez en toute sécurité avec",
        paiementMethodCardTxtP2: `le service qui vous convient`,

        mobileAppTxtTilte1: "C’est toujours aussi",
        mobileAppTxtTilte2: "Chap et facile",
        mobileAppTxtTilte3: "avec l’application mobile.",

        mobileParagrapheTxt: `Avec notre application mobile, Trouvez la résidence de vos rêves et
        réservez-la en quelques instants, où que vous soyez. Fini les
        longues attentes et les incertitudes.`,

        commentaryInfoTitle1: "Des centaines de clients satisfaits",
        commentaryInfoP:
          "Découvrez ce que notre magnifique clientèle pense de nous",
        seeAllCommentary: "Voir tous les commentaires",

        faqTxt: "FAQ",
        aProprosTxt: "A propos",
        termesConditionsTxt: "Termes et conditions",
        partenairesTxt: "Partenaires",
        // listResiTxt: "Lister ma résidence",
        listResiTxt2: "sur Trouvechap",

        recieveLastNewsTxt: `Recevez les dernières informations sur les nouveaux services et
        nouveaux produits de Trouvechap.`,

        animalAllowedTxt: "Animaux acceptés",
        smokeAllowedTxt: "Autorisation de fumer",
        eventAllowedTxt: "Evènements autorisés",

        allRightReservedTxt: "Tous droits réservés",
        needConnectTxt: "Vous devez vous connecter pour continuer",
        demandeReservationTxt: "Demande une réservation",
        whenYouWantTravelTxt: "Quand voulez-vous voyager ?",
        titleConfirmReservationTxt: "Confirmer la réservation",

        montantToBePaidTxt: "Montant à payer",
        recapTxt: "Récapitulatif",
        jourTxt: "Jour",
        // nightTxt: "Nuit",
        needReservationParamsTxt:
          "Vous devez d'abord selectionner une résidence",
        OneWeekTxt: "1 semaine",
        addTravelerTxt: "Ajouter des voyageurs",
        searchDestination: "Chercher une destination",
        placeholderDestinationTxt: "Où désirez-vous aller?",
        lastSearchTxt: "Dernières recherches",

        destinationInputError: "Veuillez entrer une destination",
        addDateInputError: "Veuillez entrer une date d'arrivée et de départ",
        addPersonneInputError: "Veuillez entrer le nombre de personne",
        fourchetPriceTxt: "Fourchette de prix",
        filterTxt: "Filtres",
        youNeedConnect1Txt: "Vous devez être connecté",
        youNeedConnect: "Vous devez être connecté pour accéder à cette page.",
        loadFavorisTxt: "Chargement de vos favoris en cours",
        loadNotificationTxt: "Chargement de vos notifications en cours",
        createFirstFavorisTxt: "Créez votre première liste de favoris",
        favorisTxt: "Favoris",
        addFavorisDone: "Residence ajoutée à vos favoris",
        seconPFavorisTxt: `Lors de vos recherches, cliquez sur le cœur pour enregistrer
        les logements et les expériences qui vous intéressent dans vos
        favoris.`,
        // loadNotificationTxt: "Chargement de vos notifications en cours",
        noNotificationTxt: "Vous n'avez aucune notification",

        errorSendNewsLetter:
          "Cette adresse email est déjà présente dans notre newsletter",
        addNewsLetterDone: "Vous êtes bien inscrit à notre newsletter",
        mesResiMenuTxt: "Mes résidences",
        calendarMenuTxt: "Calendrier",
        reservationMenuTxt: "Réservations",
        statistiquesMenuTxt: "Statistiques",

        tailleMaxFile: "Le fichier",
        tailleMaxFile2: "depasse la maximale autorisée (8 Mo)",
        uniquementFile:
          "Uniquement les fichiers du type png, jpeg et pdf sont acceptés",
        pieceUploadError:
          "Une erreur est survenue lors de l'upload de la piece",
        pieceUploadDone: "Piece uploadée avec succès",
        deposerPieceVerificationTxt:
          "Déposez vos fichiers pour la vérification",
        pieceAcceptsTxt: "Nous acceptons seulement les PDF et PNG",
        completeProfileTxt1: "Complétez votre profil",
        addPiceIdentityTxt: "Ajoute, ta piece d'identité",
        piceInCheckTxt: "Pièce active",
        piceInCheck2Txt: "Pièce en cours d'utilisation",
        piceInCheck23xt: "Cliquez sur la pièce pour la voir ou la modifier",
        pieceValideTxt:
          "Votre piece à été validé, poster dès a présent vos résidences",
        pieceValidTxt2: "Pièce validé",
        pieceValidTxt3: "Impossible de la modifier / contacter le support",
        confirmSendPieceTxt: "Confirmez-vous l'envoi de votre pièce ?",

        informationPersonnelleTxt: "Informations personnelles",

        tableResidences: "Résidences",
        tableStatut: "Statut",
        tableChambres: "Chambres",
        tableBathroom: "Salles de bain",
        tableBed: "Lits",
        tablePersons: "Personnes",
        tableLocalisation: "Localisation",
        tableLastModification: "Derniere modification",
        modifierTxt: "Modifier",
        todayTxt: "Aujourd'hui",
        yesterdayTxt: "Hier",
        apercuResidenceTxt: "Aperçu de la résidence",
        seeCalendarTxt: "Voir le calendrier",
        deleteBtn: "Supprimer",
        disableBtn: "Désactiver",
        addResidence: "Ajouter une résidence",

        checkedTxt: "Vérifications effectuées",

        pieceVerifierTxt: "Pièce d'identité",
        searchRidencePlaceholder: "Chercher une résidence",
        activationAccount: "Activation de votre adresse email trouvechap",
        checkEmailLabel: "Verification de votre adresse email",
        errorEmailVerif:
          "Le token utilisé est invalide, veuillez en demander un autre depuis le menu profil",
        accountAlreadyVerified: "Votre compte est déjà vérifié",
        accountAlreadyVerified2:
          "Votre adresse mail à été vérifiée avec succès, à plus tard sur trouvechap",
        accountVerified3Txt: "Votre adresse mail est vérifié",

        noReservationTxt: "Vous n'avez aucune réservation",

        waitingHotelTxt: "En attente de l'hôte",
        waitingPaiementTxt: "En attente de paiement",
        paiementAcceptTxt: "Paiement accepté",
        paiementCancelTxt: "annulé",
        paiementCancelByHote: "Réfusée par l'hôte",
        amountToBePaidTxt: "Montant à payer",
        reservationDoneRsvTxt: "Terminé",
        seeDetailsTxt: "Voir les détails",

        passToPaidTxt: "Passer au paiement",
        cancelTxtPaid: "Annuler",

        // cancelReservationTxt: "Annuler la réservation",
        page404infoTxt:
          "La page ou résidence que vous recherchez n'existe pas !",
        page404btnTxt: "Retour à l'accueil",
        endOfSearchTxt: "Fin des résultats !",
        infoTxtResultatNone: "Auncune résidence ne correspond à vos critères !",
        infoTxtResultatNone2: `N'hésitez pas à essayer d'autres combinaisons de filtres afin de trouver la meilleure résidence pour vous.`,
        namePrenomPlaceholderTxt: "Nom et prénoms",
        namePrenomErrorTxt: "Veillez renseigner un nom et prénoms correct",
        emailPlaceholderTxt: "Adresse email",
        emailPlaceholderErrorTxt:
          "Veillez renseigner une adresse email correct",
        numeroTelephonePlaceholderTxt: "Numéro de téléphone",
        numeroTelephonePlaceholderErrorTxt:
          "Veillez renseigner un numéro de téléphone correct précédé de l'indicatif +xxx",
        adresseGeoPlaceholderTxt: "Adresse géographique",
        adresseGeoPlaceholderErrorTxt:
          "Veillez renseigner une adresse géographique correct",
        villePlaceholderTxt: "Ville",
        villePlaceholderErrorTxt: "Veillez renseigner une ville correct",
        codeZipPlaceholderTxt: "Code zip",
        codeZipPlaceholderErrorTxt: "Veillez renseigner un code zip correct",
        provincePlaceholderTxt: "Province",
        provincePlaceholderErrorTxt: "Veillez renseigner une province correct",
        paysCountryPlaceholderTxt: "Pays",
        paysProvincePlaceholderTxt: "Province",
        paysCountryPlaceholderErrorTxt: "Veillez renseigner un pays correct",

        contactAssistanceWithWhaTitleTxt: "Contactez l'assistance via Whatsapp",
        paragraphWhaTxt:
          "Vous pouvez nous contacter via Whatsapp pour toute assistance",
        btnWhaTxt: "Contacter",
        feeOfService: "Frais de service",
        confirmReservationTxt: "Confirmer la réservation",
        confirmInfoTxt:
          "Vous ne serez pas débité maintenant. L'hote devra accepter votre demande de réservation avant de procéder au paiement.",
        confirmSaveBillingTxt:
          "Vous pouvez sauvegarder vos informations de facturation pour les prochaines réservations",
        confirmPaiementTxt: "Confirmer",
        saveBillingTxt: "Enregistrer les informations",
        setSaveBillingTxt: "Enregistrer les informations",
        saveAndReserveBillingTxt: "Enregistrer et réserver",
        justReserveTxt: "Juste réserver",
        reservationDoneTxt: "Réservation effectuée avec succès",
        posResidenceTxt: "Position de ma résidence",
        //step 1
        step1Lbl: "ETAPE 1",
        step1Tilte: "Parlez-nous de votre logement",
        step1SubTitle: `Au cours de cette étape, nous allons vous demander des
        informations sur le logement que vous proposez et si les clients
        pourront le réserver dans son intégralité ou si vous ne louez
        qu'une chambre. Nous vous demanderons ensuite d'indiquer son
        emplacement et sa capacité d'accueil.`,
        //step 7
        step7Title: "Quelles sont les commodités offertes par votre logement ?",
        step7SubTitle: `Vous pourrez ajouter des équipements une fois votre annonce
        publiée.`,
        //step 8
        step15Title: "Rédigez votre règlement intérieur",
        step15SubTitle: `Les voyageurs sont tenus de respecter votre règlement intérieur et peuvent être exclus s'ils causent des problèmes.`,
        //step 9
        titleLabel1: "Événements autorisés",
        paragraphL1: `Votre résidence est disponible pour des activités sociales
        spécifiques, comme des fêtes ou des événements.`,
        titleLabel2: "Autorisation de fumer",
        titleLabel3: "Autorisation de fumer",

        retourTxtAdd: "Retour",
        continuerTxtAdd: "Continuer",
        letsGoTxtAdd: "C’est parti !",
        finishAddTxt: "Terminer",
        nextTxtAdd: "Suivant",
        perfectAdd: "Perfect",
        confirmSendResidenceTxt: "Confirmez-vous l'envoi de votre résidence ?",
        paragraphConfirmSendResidenceTxt:
          "Vous pouvez modifier votre résidence une fois celle-ci publiée.",
        tcLearnBefore:
          "(Trouvechap etudiera votre résidence avant de la publier)",
        resiAdded: "Résidence ajoutée avec succès",

        //step17
        step18Title: "Félicitations",
        step18SubTitle: `Votre résidence à été soumise à une vérification par l’équipe de Trouvechap et sera bientôt ajoutée à notre catalogue. Vous allez maintenant basculer en mode Hôte pour régler vos paramètres de réservations.`,
        continueToHostMode: "Continuer vers le mode hôte",

        reservedTxt: "Réservé",

        residenceOneTxt: "Résidence",
        allResidencesTxt: "Toutes les résidences",
        selectAnnonceTxt: "Sélectionnez une annonce",
        basePriceTxt: "Prix de base",
        weekendPriceTxt: "Prix du week-end sur mesure",
        ajouterBtnTxt: "Ajouter",
        priceResidenceLabelTxt: "Prix de la résidence",
        priceResidenceWeekendTxt: "Nuits du vendredi et du samedi",
        fontionNotAvailableTxt:
          "Fonctionnalité non disponible pour le moment, vous recevrez une notification lorsque celle-ci sera disponible",
        reductionTxt: "Réductions",
        reductionTxt0: "Réduction",
        reductionLenghtTxt:
          "Activez des réductions pour attirer plus de clients",
        reductionLenghtTxtSeven: "Pour 7 nuits",
        reductionLenghtTxtMonth: "Pour 28 nuits",
        errorSaveReductionTxt:
          "Une erreur est survenue lors de l'enregistrement des réductions",
        reductionNotAvailableTxt:
          "Réductions non disponibles, vous recevrez une notification lorsque celle-ci sera disponible sur Trouvechap, les options ne seront pas sauvegardées",
        noResidenceTxt: "Vous n'avez aucune résidence",
        confirmationAcceptReservationTxt: "Confirmer la réservation",
        noResidenceTxt0:
          "Ajouter une résidence depuis le menu lister ma résidence",
        reservationMenuTxtLabel: "Réservations",
        lastUpdateTxt: "Dernière mise à jour : Aujourd'hui",
        // confirmReservationTxt: "Confirmer une venue",

        sorryCantAcceptNow:
          "Désolé, je ne peux pas accepter de réservation pour le moment",
        residenceEnRepairTxt: "Résidence en réparation",
        residenceAlreadyBookedTxt: `Ma résidence est déjà réservé pour ces dates`,
        customCancelBookingTxt: "Ecrire une annulation personnalisée",
        writeCancelBtnTxt: "Ecrire la raison",
        cancelledReservationTxt: "Réfuser la réservation",
        motifDeRefusTxt: "Motif de refus",
        gainsTotauxTxt: "Gains totaux",

        clientConfirmerTxt:
          "Client confirmé, Merci de nous aider a vous protéger",

        refuseBtnTxtCancel: "Annuler",
        refuseBtnTxt: "Refuser",
        acceptBtnTxt: "Accepter",
        confirmVenueTxt:
          "Confirmer la venue du client dans votre résidence, cela permettra de tracer votre résidence et de vous protéger en cas de litige",
        repondreTxtBtn: "Répondre",
        repondreCommentaireTxt: "Répondre au commentaire",
        clientSendMailDoneTxt: "Mail envoyé avec succès",
        dataTestCantAdd: "Vous ne pouvez pas ajouter de données de test",
        repondrecommentaireParagraph:
          "Votre réponse sera envoyée par mail au client, de ce fait, veuillez à être courtois et professionnel",
        performancesTitle: "Performances",
        noteGlobaleTxt: "Notes globales",
        gainsTxt: "Gains",
        avisClientTxt: "Avis des clients",
        informationsTxt: "Informations",
        percentStars5Txt: "Pourcentage des 5 étoiles",
        cumulNoteTxt: "Note cumulée globale",
        // gainsTxt: "Gains",
        globalChartParagraph:
          "Votre note moyenne de qualité globale s'affichera ici dès qu'un invité laissera un commentaire. ",
        chartNotAvailableTxt:
          "Graphique non disponible, revenez plus tard, nous vous enverrons une notification lorsque celui-ci sera disponible",
        selectPeriodeTxt: "Sélectionnez une période",
        selectResiTxt: "Choisissez une résidence",
        beneficeForTxt: "bénéfices enregistrés pour",
        detailsTxtGains: "Détails",
        allResidenceTxt: "Toutes les résidences",
        // avisClientTxt: "Avis clients",
        noAvisClientTitle: "Les avis clients s’afficheront ici",
        noAvisClientP:
          "Nous vous informerons dès qu’un clients aura laissé des remarques",
        generaleInfoTxt: "Informations générales",
        consultationLastTxt: "Consultations au cours des 30 derniers jours",
        newReservationTxt: "Nouvelles réservations, 30 derniers jours",
        tauxConversionLast: "Taux de conversion, 30 derniers jours",
        dureeMoyenneSejour: "Durée moyenne des séjours",
        trafficTxt: "Trafic",
        annulationReservationsTxt: "Annulation des réservations",
        consultationsTxtSelect: "Consultations",
        reservationsTxtSelect: "Réservations",
        conversionTxtSelect: "Conversions",
        durreSejourTxtSelect: "Durée du séjour",
        residenceTxtTable: "Résidence",
        tauxAnnulationTxtTable: "Taux d'annulation",
        tauxReservationTxtTable: "Taux de réservation",
        showTableInfoTxt: "Affichage de 1 à 10",

        noneTxtNote: "N/A",
        errorSendFavorite:
          "Une erreur est survenue lors de l'ajout aux favoris",
        waitiwaitingTxtngHotelTxt: "En attente de l'hôte",
        partialTxt: "Partiel",
        fullResidenceTxt: "Résidence complète",
        amountToGiveTxt: "Montant à rembourser : ",
        commentaryNotAllowTxt:
          "Vous ne pouvez pas commenter cette résidence, avant d'y avoir séjourné",
        contactInfoTitle1: "Nous serons ravis de vous écouter",
        contactInfoP:
          "Ecrivez-nous pour toutes vos questions ou préoccupations.",
        sendMailConfirm: "Mail envoyé avec succès",
        errorSendMail: "Une erreur est survenue lors de l'envoi du mail",
        remboursementTxt: "Remboursement",
        doAskRemboursementTxt: "Demander un remboursement",
        remboursementAskTxt: "Demande de remboursement",
        confirmRemboursementTxtDone: "Demande de remboursement effectuée",
        confirmRemboursementTxtDone2: "Vous serez contacté par mail",
        confirmRemboursementTxtError:
          "Une erreur est survenue lors de la demande de remboursement, veillez reessayer plus tard",
        confirmRemboursementTxt: "Confirmer la demande",
        errorCancelReservationTxt:
          "Une erreur est survenue lors de l'annulation",
        remboursementInfoTxtP: "Vous serez remboursé a hauteur de",
        remboursementInfoTxtP2: ".... selon les conditions d'annulation",
        infoRemboursementWarningTxt:
          "Vous serez remboursé selon les conditions d'annulation de l'hôte au moment de la réservation, veillez à bien les consulter avant de demander un remboursement",
        resiErrorAddTxt:
          "Une erreur est survenue lors de l'ajout de la résidence",
        errorSaveResidence:
          "Une erreur est survenue lors de l'enregistrement de la résidence",
        confirmTxt: "Confirmer",
      },
    },
  },
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
