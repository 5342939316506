import React, { useEffect, useState } from "react";
import { Star } from "../../images/icons/JsxIcons";
import {
  handleFormatNumber,
  handleFormatToLocalString,
} from "../../services/usePersoFormat";
import moment from "moment";
import { UserPreferences } from "../../store/UserPreferences";
import paiementJsonFee from "../../data/paiementFee.json";

import mastercard from "../../images/icons/paiement/mastercard.png";
import moovmoney from "../../images/icons/paiement/moovmoney.png";
import mtnmomo from "../../images/icons/paiement/mtnmomo.png";
import om from "../../images/icons/paiement/om.png";
import paypal from "../../images/icons/paiement/paypal.png";
import visa from "../../images/icons/paiement/visa.png";
import wave from "../../images/icons/paiement/wave.png";
import Tooltip from "../molecules/Tooltip";
import useCurrentHeight from "../../services/useCurrentHeight";
import { checkDateJust, handlePreviewPrice } from "../../services/utils";

export default function SideArticleReservation({
  headerShow,
  t,
  data,
  reservationCard,
}) {
  const imgPaiement = [
    <img src={visa} alt="visa-icon" />,
    <img src={mastercard} alt="mastercard-icon" />,
    <img src={paypal} alt="paypal-icon" />,
    <img src={wave} alt="wave-icon" />,
    <img src={om} alt="om-icon" />,
    <img src={mtnmomo} alt="mtnmomo-icon" />,
    <img src={moovmoney} alt="moovmoney-icon" />,
  ];
  const [currentTypePaiement, setCurrentTypePaiement] = useState({
    paiementFee: paiementJsonFee.data[0],
    img: imgPaiement[0],
  });
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = UserPreferences((state) => state.language);
  const [priceCalculResult, setPriceCalculResult] = useState(<></>);

  const [totalPriceCalcul, setTotalPriceCalcul] = useState(0);
  const [totalPrice, setTotalPrice] = useState(<></>);
  const [fraisPrice, setFraisPrice] = useState(0);

  const [totalPricing, setTotalPricing] = useState(0);

  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [loadingPreview, setLoadingPreview] = useState(false);

  useEffect(() => {
    let totalPrice = 0;
    let priceBase = 0;
    let daysCount = 0;
    let fraisPricing = 0;

    const date1 = moment(data.startDate).startOf("day");
    const date2 = moment(data.endDate).endOf("day");
    daysCount = date2.diff(date1, "days");
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    if (checkDateJust(data.startDate) && checkDateJust(data.endDate)) {
      setLoadingPreview(true);
      handlePreviewPrice(
        data.startDate,
        data.endDate,
        data.nbrPersonne,
        data.residenceId,
        false
      ).then((data) => {
        subTotalPriceN = data.subTotalPrice;
        fraisPriceN = data.fraisPrice;
        totalPriceResidenceN = data.totalPriceResidence;
        setSubtotalPrice(subTotalPriceN);
        setFraisPrice(fraisPriceN);
        setTotalPrice(
          <p className="info">{`${handleFormatToLocalString(
            subTotalPriceN,
            language
          )} ${showDevise}`}</p>
        );
        setTotalPricing(totalPriceResidenceN);
        // setDaySave(daysDifference);
        setPriceCalculResult(
          // <p className="info">{`Sous total ${handleFormatToLocalString(
          //   subTotalPriceN,
          //   language
          // )}${" "}${showDevise}`}</p>
          <p className="info">{`Sous total`}</p>
        );
        setReductionApply(true);
        setLoadingPreview(false);
      });
      return;
    }
    setReductionApply(false);

    if (daysCount <= 0) daysCount = 1;

    if (
      devise.toString().toLocaleLowerCase() === "Franc CFA".toLocaleLowerCase()
    ) {
      priceBase = data.residencePrice;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase()
    ) {
      priceBase = data.residencePriceEuro;
    } else if (
      devise.toString().toLocaleLowerCase() === "Dollar US".toLocaleLowerCase()
    ) {
      priceBase = data.residencePriceDollars;
    }
    priceBase = data.total ? Math.round(data.subtotal / daysCount) : priceBase;
    totalPrice = priceBase * daysCount;
    setPriceCalculResult(
      <p className="info">{`${handleFormatToLocalString(
        priceBase,
        language
      )} ${showDevise} x ${daysCount} ${t("nightTxt")}${
        daysCount > 1 ? "s" : ""
      }`}</p>
    );
    let totalPriceWithNight = 0;
    let totalPriceResidence = 0;
    totalPriceResidence = Math.round(daysCount * priceBase);
    totalPriceWithNight = totalPriceResidence;
    fraisPricing = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPricing.toString().includes(".")) {
      fraisPricing = fraisPricing.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPricing;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }
    setFraisPrice(fraisPricing);
    setTotalPricing(totalPriceResidence);
    setTotalPriceCalcul(totalPrice);
    setTotalPrice(
      <p className="info">{`${handleFormatToLocalString(
        totalPriceWithNight,
        language
      )} ${showDevise}`}</p>
    );
  }, []);

  const { windowHeight } = useCurrentHeight();

  return (
    <article
      style={{ top: headerShow && windowHeight > 550 ? 170 : 105 }}
      className="calcul-recap-residence | flcolm"
    >
      <div className="residence-part | flrow liner">
        <img
          style={{ objectFit: "cover" }}
          src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${data.residenceThumb}`}
          alt="residence-thumb"
        />
        <div className="right-part | flcolm ">
          <label>{data.residenceName}</label>
          <div className="stars-part | flrow liner">
            <div className="rating-part | flrow liner">
              <Star />
              <span>{data.residenceStars}</span>
            </div>
            <span>{data.residenceLocation}</span>
          </div>
        </div>
      </div>
      <div className="price-detail | flcolm">
        <label>{t("detailsPricetxt")}</label>
        <div className="price-recap | flrow btw liner">
          {priceCalculResult}
          {totalPrice}
        </div>
      </div>
      <div className="price-frais | flcolm">
        <label>{t("fraisTxt")}</label>
        <div className="price-recap | flrow btw liner">
          <p className="info hv-tooltip">
            {/* {`${feeOfService} ${currentTypePaiement.paiementFee.value}%`} */}
            {t("feeOfService")}
            <Tooltip minWidth={200}>
              Les frais servent au bon fonctionnement de trouvechap et le
              support 24H/24H.
            </Tooltip>
          </p>
          <p className="info red-txt">{`${handleFormatToLocalString(
            fraisPrice.toFixed(0),
            language
          )} ${showDevise}`}</p>
        </div>
      </div>
      <div className="total-price">
        <div className="price-recap | flrow btw liner">
          <p>Total</p>
          <p>{`${handleFormatToLocalString(
            totalPricing,
            language
          )} ${showDevise}`}</p>
        </div>
      </div>
    </article>
  );
}
