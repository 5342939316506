import React, { useEffect, useRef, useState } from "react";
import json from "../../data/countries.json";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Warning } from "../../images/icons/JsxIcons";
import chevronDown from "../../images/icons/chevron-down.svg";
import paiementLineUp from "../../images/icons/lineup.png";
import { get, useGetDelayRequest } from "../../services/useFetchAction";
import "../../styles/components/buyWithReservation.css";
import { InputSelectTel, InputSelectText } from "../molecules/InputSelect";

export default function BuyWithReservation({
  t,
  setCanAskReservation,
  currentCountrySelected,
  setCurentCountrySelected,
  setBadRenseignement,
  stateName,
  setStateName,
  stateTel,
  setStateTel,
  stateMail,
  setStateMail,
  stateAdress,
  setStateAdress,
  stateVille,
  setStateVille,
  stateZip,
  setStateZip,
  stateProvince,
  setStateProvince,
  currentProvinceSelected,
  setCurentProvince,
  reservationCard,
  currentSelectedPaiement,
  setCurrentSelectedPaiement,
}) {
  let valueAsk =
    stateName.good &&
    stateTel.good &&
    stateMail.good &&
    stateAdress.good &&
    stateVille.good &&
    stateZip.good &&
    currentCountrySelected;

  if (currentCountrySelected && currentCountrySelected.province.length > 0) {
    valueAsk = valueAsk && currentProvinceSelected;
  }

  if (reservationCard) {
    valueAsk = valueAsk && currentSelectedPaiement;
  }
  //retourner dans setBadRenseignement l'information mal renseigné
  useEffect(() => {
    setBadRenseignement({
      name: stateName.good,
      tel: stateTel.good,
      mail: stateMail.good,
      adress: stateAdress.good,
      ville: stateVille.good,
      zip: stateZip.good,
      country: currentCountrySelected,
      province: currentProvinceSelected,
      paiement: currentSelectedPaiement,
    });
  }, [
    stateName,
    stateTel,
    stateMail,
    stateAdress,
    stateVille,
    stateZip,
    currentCountrySelected,
    currentProvinceSelected,
    currentSelectedPaiement,
  ]);
  setCanAskReservation(valueAsk);

  const [countries, setCountries] = useState([]);
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [isSelectVisibleProvince, setIsSelectVisibleProvince] = useState(false);
  const [alreadyFetch, setAlreadyFetch] = useState(false);
  const [fetchFinish, setFetchFinish] = useState(false);

  const [provinceList, setProvinceList] = useState([]);
  useEffect(() => {
    if (currentCountrySelected) {
      setCurentProvince(null);
      if (currentCountrySelected.province.length == 0) {
        setIsSelectVisibleProvince(false);
        setCurentProvince(null);
      }
    }
  }, [currentCountrySelected]);

  useEffect(() => {
    // if (alreadyFetch || isSelectVisible == false) return;
    if (alreadyFetch) return;
    const fetchData = async () => {
      const countrieDb = await get(requestEndpoint.getCountries);
      setCountries(countrieDb.data);
      setAlreadyFetch(true);
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        if (response.ok) {
          const data = await response.json();
          const countryData = data.map((country) => ({
            countryCode: country.cca2,
            flagPath: country.flags.svg,
          }));
          setCountries(countryData);
          let currentDataPays = [];
          for (let index = 0; index < json.length; index++) {
            for (let jindex = 0; jindex < countryData.length; jindex++) {
              if (
                json[index].code.toString().toUpperCase() ==
                countryData[jindex].countryCode.toString().toUpperCase()
              ) {
                currentDataPays.push({
                  ...json[index],
                  flag: countryData[jindex].flagPath,
                });
                // break;
              }
            }
          }
          const countrieDb = await get(requestEndpoint.getCountries);
          let newCurrentDataPays = [];
          countrieDb.data.forEach((element) => {
            let find = false;
            let currentElement = { ...element };
            let finded = {};
            for (let index = 0; index < currentDataPays.length; index++) {
              if (
                currentDataPays[index].code.toString().toUpperCase() ==
                currentElement.iso2.toString().toUpperCase()
              ) {
                finded = currentDataPays[index];
                find = true;
                break;
              }
            }
            if (!find) {
              newCurrentDataPays.push({
                ...currentElement,
                flag: null,
              });
            } else {
              newCurrentDataPays.push({
                ...finded,
                name: currentElement.name,
                id: currentElement.id,
                province: currentElement.province,
              });
            }
          });

          setCountries(newCurrentDataPays);
          // setCurentCountrySelected(currentDataPays[53]);
          setFetchFinish(true);
        } else {
          console.error("Réponse HTTP non réussie :", response);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données des pays",
          error
        );
      }
    };

    fetchData();
  }, [isSelectVisible]);

  useEffect(() => {
    if (currentCountrySelected) {
      setProvinceList(currentCountrySelected.province);
    }
  }, [currentCountrySelected]);
  const selectRef = useRef(null);
  const selectRefProvince = useRef(null);
  const selectRefPaiement = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsSelectVisible(false);
    }
    if (
      selectRefProvince.current &&
      !selectRefProvince.current.contains(event.target)
    ) {
      setIsSelectVisibleProvince(false);
    }
    if (
      selectRefPaiement.current &&
      !selectRefPaiement.current.contains(event.target)
    ) {
      setPaiementIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [paiementIsVisible, setPaiementIsVisible] = useState(false);
  const [listPaiementMethod, setListPaiementMethod] = useState([]);
  const [dataPaiement, loadingPaiement, errorPaiement, fetchData] =
    useGetDelayRequest({
      route: requestEndpoint.payment_methods,
      delay: 0,
      loadingTime: 0,
    });
  useEffect(() => {
    if (loadingPaiement) return;
    if (dataPaiement) {
      let list = [];
      dataPaiement.data.map((item) => {
        list.push({
          ...item,
          selected: false,
        });
      });
      setListPaiementMethod(list);
    }
  }, [dataPaiement, loadingPaiement]);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="paiement-recap | flcolm">
      <label>{t("buyWithLbl")}</label>
      <img src={paiementLineUp} alt="lineup" />
      <div className="flcolm | facturation">
        <label>{t("facturationTxt")}</label>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="factu-input | flcolm"
        >
          {reservationCard && (
            <div
              ref={selectRefPaiement}
              className={`contains-paiement-method ${
                paiementIsVisible ? "open" : ""
              } | rlve maxwidth `}
            >
              {dataPaiement &&
                listPaiementMethod.length > 0 &&
                paiementIsVisible && (
                  <div className="modal-choose-paiement | flcolm">
                    {listPaiementMethod.map((item, index) => (
                      <button
                        key={index + "paiement-btn"}
                        type="button"
                        className={`paiement-method btn ${
                          item == currentSelectedPaiement ? "selected" : ""
                        } | flrow liner`}
                        onClick={() => {
                          setCurrentSelectedPaiement(item);
                          setPaiementIsVisible(false);
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PAIEMENT}${item.icon}`}
                          alt="paiement-method"
                        />
                        <span>{item.label}</span>
                      </button>
                    ))}
                  </div>
                )}

              <button
                id="paiement-billing"
                onClick={() => setPaiementIsVisible(!paiementIsVisible)}
                className={`btn choose-paiement-method ${
                  paiementIsVisible ? "open" : ""
                } `}
              >
                {currentSelectedPaiement ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PAIEMENT}${currentSelectedPaiement.icon}`}
                      alt="paiement-method"
                    />
                    <span
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {currentSelectedPaiement.label}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="-l">Choissisez un moyen de paiement</span>
                  </>
                )}

                <img className="chevron-img" src={chevronDown} alt="chevron" />
              </button>
            </div>
          )}

          <div className="first-step | flrow">
            <InputSelectText
              title={t("namePrenomPlaceholderTxt")}
              error={t("namePrenomErrorTxt")}
              showError={true}
              regex={/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/}
              myState={stateName}
              setMyState={setStateName}
              className="name-billing"
              upper={true}
              trim={true}
              minimun={3}
            />
            <InputSelectTel
              title={t("numeroTelephonePlaceholderTxt")}
              error={t("numeroTelephonePlaceholderErrorTxt")}
              showError={true}
              myState={stateTel}
              setMyState={setStateTel}
              className="tel-billing"
              minimun={10}
            />
            <InputSelectText
              title={t("emailPlaceholderTxt")}
              error={t("emailPlaceholderErrorTxt")}
              showError={true}
              regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
              myState={stateMail}
              setMyState={setStateMail}
              className="email-billing"
              upper={false}
              trim={true}
              fullLower={true}
            />
            <InputSelectText
              title={t("adresseGeoPlaceholderTxt")}
              error={t("adresseGeoPlaceholderErrorTxt")}
              showError={true}
              regex={/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s-]+$/}
              myState={stateAdress}
              setMyState={setStateAdress}
              className="adress-billing"
              trim={true}
              oneUpper={true}
              minimun={2}
              replaceeme={true}
            />
            <InputSelectText
              title={t("villePlaceholderTxt")}
              error={t("villePlaceholderErrorTxt")}
              showError={true}
              regex={/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/}
              myState={stateVille}
              setMyState={setStateVille}
              className="ville-billing"
              upper={true}
              trim={true}
              minimun={3}
              replaceeme={true}
            />
            <InputSelectText
              title={`${t("codeZipPlaceholderTxt")} ex: 00225`}
              error={t("codeZipPlaceholderErrorTxt")}
              showError={true}
              regex={/^[A-Za-z0-9\s-]+$/}
              myState={stateZip}
              setMyState={setStateZip}
              className="zip-billing"
              trim={true}
              oneUpper={true}
              upper={true}
              minimun={2}
            />
            {/* <InputSelectTel
              title={t("codeZipPlaceholderTxt")}
              error={t("codeZipPlaceholderErrorTxt")}
              showError={true}
              myState={stateZip}
              setMyState={setStateZip}
              className="zip-billing"
              minimun={2}
              notCheckMore={false}
            /> */}
          </div>
          <span
            id="selecteur"
            className={`input-select-perso country-billing ${
              isSelectVisible ? "selected" : ""
            } | rlve`}
          >
            {alreadyFetch && (
              <div
                ref={selectRef}
                className={
                  isSelectVisible
                    ? "select-visible select-input | flcolm"
                    : "select-input | flcolm"
                }
              >
                {countries.map((country, index) => (
                  <button
                    type="index"
                    className="select-btn | flrow liner"
                    key={index}
                    value={country.name}
                    onClick={() => {
                      setCurentCountrySelected(country);
                      setIsSelectVisible(false);
                    }}
                  >
                    {fetchFinish && (
                      <img
                        style={{ width: 24, height: 24 }}
                        src={country.flag}
                        alt={country.flag}
                      />
                    )}{" "}
                    <span> {country.name}</span>
                  </button>
                ))}
              </div>
            )}
            <button
              id="country-billing"
              type="button"
              onClick={() => setIsSelectVisible(!isSelectVisible)}
              className={"preview | flrow liner addgap8"}
            >
              {currentCountrySelected ? (
                <>
                  <img src={currentCountrySelected.flag} alt="currentFlag" />
                  <span>{currentCountrySelected.name}</span>
                </>
              ) : (
                <>
                  <span>{t("paysCountryPlaceholderTxt")}</span>
                </>
              )}

              <img className="chevron-img" src={chevronDown} alt="chevron" />
            </button>
          </span>
          {(provinceList.length > 0 || currentProvinceSelected) && (
            // <InputSelectText
            //   title={t("provincePlaceholderTxt")}
            //   error={t("provincePlaceholderErrorTxt")}
            //   regex={/^[A-Za-z0-9\s-]+$/}
            //   myState={stateProvince}
            //   setMyState={setStateProvince}
            //   className="province-billing"
            //   trim={true}
            //   upper={true}
            //   minimun={2}
            // />
            <span
              id="selecteur"
              className={`input-select-perso country-billing ${
                isSelectVisibleProvince ? "selected" : ""
              } | rlve`}
            >
              {alreadyFetch && (
                <div
                  ref={selectRefProvince}
                  className={
                    isSelectVisibleProvince
                      ? "select-visible select-input | flcolm"
                      : "select-input | flcolm"
                  }
                >
                  {provinceList.map((province, index) => (
                    <button
                      type="index"
                      className="select-btn | flrow liner"
                      key={"province" + index}
                      value={province.name}
                      onClick={() => {
                        setCurentProvince(province);
                        setIsSelectVisibleProvince(false);
                      }}
                    >
                      <span> {province.name}</span>
                    </button>
                  ))}
                </div>
              )}
              <button
                id="province-billing"
                type="button"
                onClick={() =>
                  setIsSelectVisibleProvince(!isSelectVisibleProvince)
                }
                className={"preview | flrow liner addgap8"}
              >
                {currentProvinceSelected ? (
                  <>
                    <span>{currentProvinceSelected.name}</span>
                  </>
                ) : (
                  <>
                    <span>{t("paysProvincePlaceholderTxt")}</span>
                  </>
                )}

                <img className="chevron-img" src={chevronDown} alt="chevron" />
              </button>
            </span>
          )}
        </form>
      </div>
      {reservationCard == false && (
        <div className="info-reglement-warning flrow liner addgap16">
          <div className="flex liner">
            <Warning />
          </div>
          <p>{t("reglementInfoTxt")}</p>
        </div>
      )}
    </div>
  );
}
