import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { requestEndpoint } from "../data/requestEndpoint";
import { useStoreManager } from "../store/StoreManage";
import { useStoreUserManager } from "../store/UserManage";
import { NotificationService } from "./Notification.service";
import { decryptData, encryptData } from "./encryption.service";
import { routeCreateGet } from "./getRouteManager";
import { filterObject, getLatLngFromAddress } from "./utils";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const encrytToken = localStorage.getItem("token");
    if (!encrytToken) return config;
    const decriptToken = await decryptData(encrytToken);

    const accessToken = decriptToken.access_token;
    const refreshToken = decriptToken.refresh_token;
    // console.log("Bearer " + accessToken);
    // console.log("refreshToken", refreshToken);
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (refreshToken) {
      config.headers["refresh-token"] = refreshToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  async (response) => {
    const accessToken = response.headers["new-access-token"];
    const refreshToken = response.headers["new-refresh-token"];

    if (accessToken && refreshToken) {
      // console.log("Nouveau token recu:", response.headers);
      let dataToken = {
        access_token: response.access_token,
        refresh_token: response.refresh_token,
      };
      let encryptToken = await encryptData(dataToken);
      localStorage.setItem("token", encryptToken);
    }

    return response;
  },
  (error) => {
    // console.log("error", error);
    if (error.response) {
      const userData = useStoreUserManager.getState().user;
      // console.log("userData", userData);
      if (error.response.status === 401) {
        useStoreUserManager.getState().handleDisconnectUser();
        NotificationService.addToQueue(1, "Votre session a expiré");
      }
    }
    return Promise.reject(error);
  }
);

export const useGetData = ({
  route = "",
  startUrl = process.env.REACT_APP_API_URL,
  dataFetch = {},
  testing = false,
  testingData = null,
  loadingTime = 0,
  forceFetch = false,
  log = false,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cancelToken = useRef(null);
  const abortController = new AbortController();
  useEffect(() => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      let response;
      try {
        if (loadingTime > 0)
          await new Promise((resolve) => setTimeout(resolve, loadingTime));

        response = await api.get(`${startUrl}` + route, dataFetch, {
          cancelToken: cancelToken.current.token,
          signal: abortController.signal,
        });
        if (log)
          console.log(
            "ok",
            route,
            response.data,
            error,
            testing,
            testingData,
            loadingTime
          );
        setData(response.data);
      } catch (error) {
        if (log)
          console.log("error", route, error, testing, testingData, loadingTime);
        if (axios.isCancel(error)) {
          //   console.log("Request canceled:", error.message);
        } else {
          setError(error);
        }
      } finally {
        if (testing == true) {
          if (testing && testingData) {
            console.log(testingData);
            setData(testingData);
          }
        }
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      return abortController.signal;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return [data, loading, error];
};
export const usePutData = ({
  route = "",
  dataFetch = {},
  testing = false,
  testingData = null,
  loadingTime = 0,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cancelToken = useRef(null);
  const abortController = new AbortController();

  useEffect(() => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      try {
        if (loadingTime > 0)
          await new Promise((resolve) => setTimeout(resolve, loadingTime));
        const response = await api.put(
          `${process.env.REACT_APP_API_URL}` + route,
          dataFetch,
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          //   console.log("Request canceled:", error.message);
        } else {
          setError(error);
        }
      } finally {
        if (!data && !testing && testingData) setData(testingData);

        setLoading(false);
      }
    };

    fetchData();
    return () => {
      return abortController.signal;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return { data, loading, error };
};
export const usePostData = ({
  route = "",
  dataFetch = {},
  loadingTime = 0,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cancelToken = useRef(null);
  const abortController = new AbortController();

  useEffect(() => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      try {
        if (loadingTime > 0)
          await new Promise((resolve) => setTimeout(resolve, loadingTime));
        const response = await api.post(
          `${process.env.REACT_APP_API_URL}` + route,
          dataFetch,
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          //   console.log("Request canceled:", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      return abortController.signal;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return { data, loading, error };
};
function createGetRoute(baseRoute, params) {
  /*
  viewport = { 
    northeast: {
      lat: 5.318753654016249,
      lng: -3.989898064890878
    },
    southwest: {
      lat: 5.269540442763116,
      lng: -4.030947525133178
    }
  }
  */
  const serializeObject = (obj, prefix = "") => {
    const serialized = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const prop = obj[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof prop === "object" && prop !== null && !Array.isArray(prop)) {
          serialized.push(...serializeObject(prop, newKey));
        } else {
          serialized.push(`${newKey}=${prop}`);
        }
      }
    }
    return serialized;
  };

  Object.keys(params).forEach((key, index) => {
    if (Array.isArray(params[key])) {
      let queryArray = params[key];

      queryArray.forEach((queryArrayValue, queryArrayIndex) => {
        const serialized = serializeObject(
          queryArrayValue,
          `${key}[${queryArrayIndex}]`
        );
        baseRoute += (index > 0 ? "&" : "?") + serialized.join("&");
      });
    } else if (
      typeof params[key] === "object" &&
      params[key] !== null &&
      !Array.isArray(params[key])
    ) {
      const serialized = serializeObject(params[key], key);
      baseRoute += (index > 0 ? "&" : "?") + serialized.join("&");
    } else {
      baseRoute += (index > 0 ? "&" : "?") + `${key}=${params[key]}`;
    }
  });

  return baseRoute;
}

function randomizeArray(array) {
  const newArray = array.slice();
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
export const useGetDelayRequest = ({
  route = "",
  personalRoute = "",
  dataFetch = {},
  datatest = null,
  loadingTime = 0,
  testing = false,
  delay = 0,
  log = false,
  fullyTesting = false,
  typeRequest = "get",
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [routeC, setRouteC] = useState(route);
  const [persoR, setPersoR] = useState(personalRoute);
  const [dataF, setDataF] = useState(dataFetch);
  const [currentType, setCurrentType] = useState(typeRequest);
  const shouldFetchData = useRef(false);
  const cancelToken = useRef(null);
  const [timeoutId, setTimeoutValue] = useState();
  // const [timeoutIdWaiter, setTimeoutValueWaiter] = useState();

  const fetchDataWithDelay = async () => {
    setError(null);
    setData(null);
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();

    if (loadingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, loadingTime));
    }

    if (fullyTesting && datatest) {
      setLoading(false);
      setData(datatest);
      setError(null);
      return;
    }
    try {
      const response = await api[currentType](
        `${persoR == "" ? process.env.REACT_APP_API_URL + routeC : persoR}`,
        {
          ...dataF,
          cancelToken: cancelToken.current.token,
        }
      );
      setData(response);
      setLoading(false);
      if (log) console.log(response);
    } catch (error) {
      if (log) console.log(error);
      if (!axios.isCancel(error)) {
        setError(error);
      }
      setLoading(false);
    } finally {
      if (datatest && testing && !axios.isCancel(error)) {
        setError(null);
        setData({ ...datatest, data: randomizeArray(datatest.data) });
      }
    }
  };

  const delayAndGoRequest = () => {
    clearTimeout(timeoutId);
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    setLoading(true);
    let timeout = setTimeout(() => {
      if (shouldFetchData.current) {
        fetchDataWithDelay();
      }
    }, delay);
    setTimeoutValue(timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
    };
  }, []);

  const fetchData = (
    typeR = "get",
    routing = route,
    dating = dataFetch,
    persoRoute = null
  ) => {
    if (typeR) setCurrentType(typeR);
    if (persoRoute) setPersoR(persoRoute);
    if (dating) setDataF(dating);
    if (routing) setRouteC(routing);
    shouldFetchData.current = true;
    delayAndGoRequest();
  };

  return [data, loading, error, fetchData];
};
export const useGetStoreWithDelay = (
  route = "",
  dataFetch = {},
  datatest = [],
  loadingTime = 0,
  delay = 300
) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const shouldFetchData = useRef(false);
  const cancelToken = useRef(null);
  const [timeoutId, setTimeoutValue] = useState();
  // const [timeoutIdWaiter, setTimeoutValueWaiter] = useState();

  const fetchDataWithDelay = async () => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();

    if (loadingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, loadingTime));
    }
    setData(null);
    setError(null);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}` + route,
        {
          ...dataFetch,
          cancelToken: cancelToken.current.token,
        }
      );
      setData(response.data);
    } catch (error) {
      // Vérifier si l'erreur est due à une annulation

      if (!axios.isCancel(error)) {
        setError(error);
      }
    } finally {
      if (datatest && !axios.isCancel(error)) {
        setError(null);
        setData({ ...datatest, data: randomizeArray(datatest.data) });
      }
      setLoading(false);
    }
  };

  const delayAndGoRequest = () => {
    clearTimeout(timeoutId);
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }

    setLoading(true);
    let timeout = setTimeout(() => {
      if (shouldFetchData.current) {
        fetchDataWithDelay();
      }
    }, delay);
    setTimeoutValue(timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
    };
  }, []);

  const fetchData = () => {
    shouldFetchData.current = true;
    delayAndGoRequest();
  };

  return { loading, data, error, fetchData };
};
export function getSt(endpoint, data = {}, headers = {}, controller = {}) {
  return api.get(
    endpoint,
    data,
    {
      headers: headers,
    },
    controller
  );
}
export function get(endpoint, data = {}, headers = {}, controller = {}) {
  let route = createGetRoute(
    `${process.env.REACT_APP_API_URL}` + endpoint,
    data
  );
  // console.log(route)
  return api.get(
    route,
    {
      headers: headers,
    },
    controller
  );
}

export function post(endpoint, data, headers = {}, controller = {}) {
  return api.post(
    endpoint,
    data,
    {
      headers: headers,
    },
    controller
  );
}

export function remove(endpoint, headers = {}, controller = {}) {
  return api.delete(endpoint, {
    headers: headers,
  });
}
export function removeBody(endpoint, data = {}, headers = {}, controller = {}) {
  return api.delete(
    endpoint,
    data,
    {
      headers: headers,
    },
    controller
  );
}
export function put(endpoint, data, headers = {}, controller = {}) {
  return api.put(
    endpoint,
    data,
    {
      headers: headers,
    },
    controller
  );
}

export function patch(endpoint, data, headers = {}, controller = {}) {
  return api.patch(
    endpoint,
    data,
    {
      headers: headers,
    },
    controller
  );
}

export const useGetDelayRequestStore = ({
  delay = 300,
  log = false,
  query = null,
  setQuery = null,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const shouldFetchData = useRef(false);
  const cancelToken = useRef(null);
  const [timeoutId, setTimeoutValue] = useState();

  // const [timeoutIdWaiter, setTimeoutValueWaiter] = useState();
  // const searchStoreParams = useStoreManager.getState().searchStoreParams;
  const fetchDataWithDelay = async () => {
    setError(null);
    setData(null);
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      let data = await useStoreManager.getState().searchStoreParams;
      // console.log(data);
      if (log) console.log(data);
      let startDate = data.startDate ? new Date(data.startDate) : null;
      let endDate = data.endDate ? new Date(data.endDate) : null;
      let occupation = data.occupation;
      if (startDate && endDate) {
        let year = startDate.getFullYear();
        let month = (startDate.getMonth() + 1).toString().padStart(2, "0");
        let day = startDate.getDate().toString().padStart(2, "0");
        let formattedDate = `${year}-${month}-${day}`;
        startDate = formattedDate;
        year = endDate.getFullYear();
        month = (endDate.getMonth() + 1).toString().padStart(2, "0");
        day = endDate.getDate().toString().padStart(2, "0");
        formattedDate = `${year}-${month}-${day}`;
        endDate = formattedDate;
      }

      data = { ...data, startDate, endDate, limit: 30 };
      if (!occupation) delete data.occupation;
      if (!startDate || !endDate) {
        delete data.startDate;
        delete data.endDate;
      }

      let newData = { ...data };
      let locationSearch = data.location;
      let viewPort = data.viewPort;
      if (locationSearch) {
        locationSearch = locationSearch.toString().trim();
        if (locationSearch.length > 0) {
          //encode uri de locationsearch et le remplace dans newData
          if (locationSearch) {
            if (locationSearch.length > 0) {
              locationSearch = encodeURIComponent(locationSearch.trim());
              newData = { ...newData, location: locationSearch };
            }
          }
        } else {
          locationSearch = null;
        }
      }

      if (data.location && !data.mapOpen) {
        if (data.location.length > 0) {
          try {
            const { lat, lng, viewport } = await getLatLngFromAddress(
              data.location
            );
            if (lat && lng) {
              newData = {
                ...newData,
                lat,
                lng,
              };
            }
            if (viewport) {
              viewPort = viewport;
            }
          } catch (error) {
            console.error(
              "No location found for the following address:",
              error
            );
          }
        }
      }
      if (data.mapOpen && !data.lat && !data.lng && data.location) {
        if (data.location.length > 0) {
          try {
            let search = "";
            search = data.location;
            search = search.replace("CI", "");
            search = search.replace(" CI", "");
            const { lat, lng, viewport } = await getLatLngFromAddress(
              search + " CI"
            );
            const storeParameters = useStoreManager.getState().storeParameters;
            useStoreManager.getState().handleUpdateStoreParameters({
              ...storeParameters,
              destination: "",
              lat,
              lng,
            });
            useStoreManager.getState().handleUpdateSearchStoreParameters();
          } catch (error) {
            console.error(
              "No location found for the following address:",
              error
            );
          }
        }
      }
      delete newData.viewPort;
      let endpointToGo = requestEndpoint.newGetStore;
      newData = { ...newData, viewport: viewPort };
      if (data.mapOpen && data.mapBounds) {
        endpointToGo = requestEndpoint.getResidenceByMap;
        const mapBounds = data.mapBounds;
        const mapZoom = data.mapZoom || 16;
        delete newData.page;
        delete newData.limit;
        delete newData.lat;
        delete newData.lng;
        delete newData.viewport;
        delete newData.centerMap;
        delete newData.location;
        delete newData.mapOpen;
        delete newData.mapBounds;
        delete newData.loadPreviousPage;
        delete newData.mapZoom;
        newData.zoomLevel = mapZoom;

        newData.viewport = {
          northeast: {
            lat: mapBounds.north,
            lng: mapBounds.east,
          },
          southwest: {
            lat: mapBounds.south,
            lng: mapBounds.west,
          },
        };
        // console.log("mapBounds", {
        //   northeast: {
        //     lat: mapBounds.north,
        //     lng: mapBounds.east,
        //   },
        //   southwest: {
        //     lat: mapBounds.south,
        //     lng: mapBounds.west,
        //   },
        // });
      } else {
        delete newData.mapBounds;
        if (newData.location)
          setQuery(
            {
              ...query,
              location: newData.location,
            },
            "replaceIn"
          );
        if (newData.lat && newData.lng)
          setQuery(
            {
              ...query,
              centerMap: {
                lat: newData.lat,
                lng: newData.lng,
              },
              mapZoom: 16,
            },
            "replaceIn"
          );
      }

      newData = await filterObject(newData);

      const routeForFetch = await routeCreateGet(
        process.env.REACT_APP_API_URL + endpointToGo,
        newData
      );

      const response = await api.get(routeForFetch, {
        cancelToken: cancelToken.current.token,
      });
      if (log) console.log(response);
      setData(response);
      if (log) console.log(response);
    } catch (error) {
      if (log) console.log(error);
      if (!axios.isCancel(error)) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const delayAndGoRequest = () => {
    clearTimeout(timeoutId);
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    setLoading(true);
    let timeout = setTimeout(() => {
      if (shouldFetchData.current) {
        fetchDataWithDelay();
      }
    }, delay);
    setTimeoutValue(timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
    };
  }, []);

  const fetchData = () => {
    shouldFetchData.current = true;
    delayAndGoRequest();
  };

  return [data, loading, error, fetchData];
};
