import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { get } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { UserPreferences } from "../../store/UserPreferences";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import DescriptionModal from "./DescriptionModal";
export default function DescriptionResidence({ residence = null }) {
  const [isOpen, toggle] = useState(false);
  const [isOpenSpecific, toggleSpecific] = useState(false);
  const { t } = useTranslation();
  const [currentReduction, setCurrentReduction] = useState(null);
  const [canShowReduction, setCanShowReduction] = useState(false);
  const [specificReduction, setSpecificReduction] = useState([]);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  // let description =
  //   language == "fr-FR"
  //     ? residence?.description ?? ""
  //     : residence?.descriptionEn ?? "";
  const [calculForFirstDescription, setCalculForFirstDescription] = useState(0);
  const [totalDescriptionLength, setTotalDescriptionLength] = useState(0);
  useEffect(() => {
    if (!residence) return;
    let totalLength = 0;
    residence?.description?.forEach((item, index) => {
      totalLength += item.text.length;
      if (index == 0) setCalculForFirstDescription(totalLength);
    });
    setTotalDescriptionLength(totalLength);
    // fetchDiscountData();
  }, [residence]);

  const fetchDiscountData = async () => {
    try {
      const { data } = await get(
        requestEndpoint.getAllDiscounts + residence.id
      );
      setCurrentReduction(data);
      //on check si on peut afficher les reductions
      data.nbDays.forEach((item) => {
        if (item.enable) {
          setCanShowReduction(true);
        }
      });
      let newTab = [];
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      data.specific.forEach((item) => {
        if (item.fromDate > yesterday.toISOString()) {
          newTab.push(item);
        }
      });
      //mettre les reductions par ordre croissant
      newTab.sort((a, b) => {
        return new Date(a.fromDate) - new Date(b.fromDate);
      });

      setSpecificReduction(newTab);

      data.specific.length > 0 && setCanShowReduction(true);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  //afficher la date en forme local DDDD DD MMMM YYYY avec moment, mettre DD MMMM en strong et la premiere lettre de DDDD en majuscule
  const handleFormatDate = (date) => {
    let newDate = moment(date).format("dddd DD MMMM YYYY");
    let newDateArray = newDate.split(" ");
    let newDateArray2 = [];
    newDateArray.forEach((item, index) => {
      if (index == 0) {
        let newString = item.charAt(0).toUpperCase() + item.slice(1);
        newDateArray2.push(newString + " ");
      } else if (index == 1 || index == 2) {
        newDateArray2.push(
          <>
            {" "}
            <strong key={index}>{item}</strong>
          </>
        );
      } else {
        newDateArray2.push(<> {item}</>);
      }
    });
    return newDateArray2;
  };
  return (
    <>
      <ContainerPersoModal
        isOpen={isOpen}
        toggle={toggle}
        baseTop={false}
        title={t("titleProposLogement")}
      >
        <DescriptionModal data={residence} />
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={isOpenSpecific}
        toggle={toggleSpecific}
        baseTop={false}
        title={"Periodes spécifique"}
      >
        <div className="description-modal | flcolm">
          <div className="title-modal flcolm addgap8">
            <label>Periodes spécifique</label>
          </div>
          <div style={{ rowGap: 6 }} className="contents | flcolm">
            {specificReduction?.map((item, index) => {
              return (
                <div className="maxwidth flcolm" key={index}>
                  <p>
                    • {handleFormatDate(item.fromDate)} au{" "}
                    {handleFormatDate(item.toDate)},{" "}
                    <strong>
                      {handleFormatToLocalString(item.price, language)}{" "}
                      {showDevise}
                    </strong>{" "}
                    la nuitée
                  </p>
                </div>
              );
            })}
            {/* <p>{language == "fr-FR" ? data.description : data.descriptionEn}</p> */}
          </div>
        </div>
      </ContainerPersoModal>
      {/* {(canShowReduction || residence?.weekendPriceEnable) && (
        <div className="description-residence | flcolm">
          {residence && (
            <>
              {currentReduction ? (
                <div className="maxwidth flcolm">
                  <h6>REDUCTIONS</h6>
                  <ul style={{ marginBottom: 0 }}>
                    {currentReduction.nbDays.map(
                      (item, index) =>
                        item.enable && (
                          <li key={index + "residence.id"}>
                            • <strong>-{item.percent}%</strong> de reduction de{" "}
                            pour une reservation de{" "}
                            <strong>{item.days} nuits</strong> ou plus
                          </li>
                        )
                    )}
                    {residence.weekendPriceEnable && (
                      <li>
                        • <strong>WEEKEND</strong> (vendredi nuit au samedi
                        nuit),{" "}
                        <strong>
                          {handleFormatToLocalString(
                            residence.weekendPrice,
                            language
                          )}{" "}
                          {showDevise}
                        </strong>{" "}
                        la nuitée.
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                <div className="maxwidth flcolm">
                  <h6
                    style={{
                      height: 16,
                      width: 100,
                      maxWidth: "90%",
                    }}
                    className="skeleton span-skeleton"
                  ></h6>
                  <ul style={{ marginTop: 12, rowGap: 10 }}>
                    <li
                      style={{
                        height: 16,
                        width: 200,
                        maxWidth: "70%",
                      }}
                      className="skeleton span-skeleton"
                    ></li>
                    <li
                      style={{
                        height: 16,
                        width: 500,
                        maxWidth: "90%",
                      }}
                      className="skeleton span-skeleton"
                    ></li>
                    <li
                      style={{
                        height: 16,
                        width: 450,
                        maxWidth: "70%",
                      }}
                      className="skeleton span-skeleton"
                    ></li>
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      )} */}
      {/* {specificReduction.length > 0 && (
        <div className="description-residence | flcolm">
          {residence && (
            <>
              <div className="maxwidth flcolm">
                <h6>
                  PERIODE{specificReduction.length > 1 ? "S" : ""} SPECIFIQUE
                </h6>
                <ul style={{ marginBottom: 0 }}>
                  {specificReduction.slice(0, 2).map((item, index) => (
                    <li key={index + "residence.id"}>
                      • {handleFormatDate(item.fromDate)} au{" "}
                      {handleFormatDate(item.toDate)},{" "}
                      <strong>
                        {handleFormatToLocalString(item.price, language)}{" "}
                        {showDevise}
                      </strong>{" "}
                      la nuitée
                    </li>
                  ))}
                  {specificReduction.length > 2 && <>...</>}
                </ul>
                {specificReduction.length > 2 && (
                  <button
                    onClick={() => toggleSpecific(true)}
                    className="btn"
                    style={{
                      textAlign: "left",
                      justifyContent: "flex-start",
                      marginTop: 16,
                    }}
                  >
                    <span>{t("showMoreTxt")}</span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )} */}

      <div className="description-residence | flcolm">
        {residence ? (
          <>
            {residence?.description?.slice(0, 2).map((item, index) => {
              const capitalizedTitle =
                item.title.charAt(0).toUpperCase() + item.title.slice(1);
              let capitalizedText =
                item.text.charAt(0).toUpperCase() + item.text.slice(1);
              return (
                <div className="maxwidth flcolm" key={index}>
                  <h6>
                    {capitalizedTitle == "A PROPROS"
                      ? "A PROPOS"
                      : capitalizedTitle}
                  </h6>
                  <p>{capitalizedText}</p>
                </div>
              );
            })}
            {(calculForFirstDescription > 270 ||
              totalDescriptionLength > 270 ||
              residence.description.length > 2) && (
              <button onClick={() => toggle(true)} className="btn">
                <span>{t("showMoreTxt")}</span>
              </button>
            )}
          </>
        ) : (
          <>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "78px", height: "13px" }}
              className="skeleton span-skeleton"
            ></span>
          </>
        )}
      </div>
    </>
  );
}
